import React, { useContext, useEffect, useState } from 'react'
import { NavBar } from './includes/NavBar'
import SideBar from './includes/SideBar'
import HomePrincipal from './HomePrincipal'
import { Route, Routes, useNavigate } from 'react-router-dom'
import PageNoFound from './PageNoFound'
import config from '../config'
import { GetData } from '../services/GetData'
import { SessionContext } from '../SessionContext';
import { PostData } from '../services/PostData'
import 'animate.css';
import { Usuario } from '../services/Usuario'
import PageProductos from './inventory/PageProductos'
import PageDatosEmpresa from './PageDatosEmpresa'
import PerfilUsuario from './PerfilUsuario'
import PageProveedor from './PageProveedor'
import PageCategorias from './PageCategorias'
import PageCliente from './PageCliente'
import PageCarteraClientes from './PageCarteraClientes'
import PageListaPrecios from './PageListaPrecios'
import PageCrearFactura from './PageCrearFactura'
import PageCierresCaja from './PageCierresCaja'


const HomePage = () => {

    const [DatosInstitucion, setDatosInstitucion] = useState([])
    const [EmpresaActual, setEmpresaActual] = useState([])
    const [BuscarAlumno, setBuscarAlumno] = useState("")
    const [ListadoAlumnos, setListadoAlumnos] = useState([])
    const { setIdEmpresa } = useContext(SessionContext);

    const navigate = useNavigate();
    const usuario = Usuario();
    const tipousario = parseInt(usuario.id_tipos_usuario)

  /*   useEffect(() => {
      document.body.classList.add('toggle-sidebar'); // Agregar clase al cargar el componente
  
      return () => {
        document.body.classList.remove('toggle-sidebar'); // Remover clase al desmontar el componente
      };
    }, []); */

    const handleToggleSidebar = () => {
      const body = document.body;
      const hasToggleSidebarClass = body.classList.contains('toggle-sidebar');
  
      if (hasToggleSidebarClass) {
        body.classList.remove('toggle-sidebar');
      } else {
        body.classList.add('toggle-sidebar');
      }
    };

    useEffect(() => {
      GetData('empresas/empresainicial', null).then ((result) =>{
        if (result.error === false){
            
            setEmpresaActual(result.empresa);
            setIdEmpresa(result.empresa.id_empresa)
            if (result.empresa.nit_empresa === "0"){
              //  navigate('/home/datosempresa')
            }
        }
    })
      
    }, [])

  /*   useEffect(() => {
      const data = {
          nombres: BuscarAlumno
      }
      PostData('matriculas/buscarnombre/', data).then ((result) =>{
        if (result.error === false){
          setListadoAlumnos(result.matriculas);
        }
    })
      
    }, [BuscarAlumno])
     */


    

    const ModalSedes = () => {
      const modal = document.getElementById('modalSede');

      // Verificar si el modal existe antes de intentar abrirlo
      if (modal) {
        // Usar el método modal() para abrir el modal
        const modalInstancia = new window.bootstrap.Modal(modal);
        modalInstancia.show();
      }
    }
    
   
  return (
    <>
        <header id="header" className="header fixed-top d-flex align-items-center">

        <div className="d-flex align-items-center justify-content-between ">
          <a href="/home" className="logo d-flex align-items-center animate__animated animate__flipInY">
            <img src="/assets/img/logo-sm.png" alt="" />
            <span className="d-none d-lg-block">{config.NOMBRE_APP}</span>
          </a>
          <i className="bi bi-list toggle-sidebar-btn" onClick={handleToggleSidebar}></i>
        </div>

        <div className="search-bar">
          <form className="search-form d-flex align-items-center" method="POST" action="#" onSubmit={(e) => e.preventDefault()} >
            <input type="text" name="query" autocomplete="off" placeholder="Buscar" title="Ingrese el nombre del alumno a buscar" value={BuscarAlumno} onChange={(e)=> setBuscarAlumno(e.target.value)} />
            <button type="submit" title="Search"><i className="bi bi-search"></i></button>
          </form>
         


        </div>

        <div className="search-bar">
               <strong>Empresa:</strong> <span className='card-title'>{EmpresaActual.nombre_empresa}</span>  
              {/*  <button type="submit" className='btn btn-primary btn-sm ml-2' title="Cambiar Empresa" onClick={() => ModalSedes()}><i className="bi bi-signpost-2"></i> Cambiar Empresa</button>           */}
        </div>

            <NavBar />

        </header>

        {/* *** Sidebar ** */}
            <SideBar />

            <main id="main" className="main">
              <div className='container'>
              <Routes>
                <Route path="/" element={<HomePrincipal />} />
                <Route path="/principal" element={<HomePrincipal />} />
                <Route path="/productos" element={<PageProductos />} />
                <Route path="/empresa" element={<PageDatosEmpresa />} />
                <Route path="/proveedores" element={<PageProveedor />} />
                <Route path="/clientes" element={<PageCliente />} />
                <Route path="/facturaventa" element={<PageCrearFactura />} />
                <Route path="/listadoprecios" element={<PageListaPrecios />} />
                <Route path="/carteraclientes" element={<PageCarteraClientes />} />
                <Route path="/categorias" element={<PageCategorias />} />
                <Route path="/perfilusuario" element={<PerfilUsuario />} />
                <Route path="/cierrecaja" element={<PageCierresCaja />} />
       
                <Route path="*" element={<PageNoFound />} />
              </Routes>
              </div>
            </main>


        <footer id="footer" className="footer">
        <div className="copyright">
          &copy; Copyright <strong><span>{config.EMPRESA}</span></strong>. All Rights Reserved
        </div>
        <div className="credits">  
          Designed by <a href={config.URL_EMPRESA}>{config.EMPRESA}</a>
        </div>
        </footer>

        <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>

       
    </>
  )
}

export default HomePage