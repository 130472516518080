import React, { useEffect, useState } from 'react'
import { PostData } from '../../services/PostData'
import Swal from 'sweetalert2';
import config from '../../config';
import 'animate.css';
import { Link, useNavigate, useParams } from 'react-router-dom';

const ConfirmarEmailPage = () => {
  const {error} = useParams();
  const [Confirmado, setConfirmado] = useState(error)

  const navigate = useNavigate();

  useEffect(() => {
      localStorage.setItem('URL_SERVICIOS', config.API_URL_PPAL);   
  }, [])
 
  


  return (
  <main className='fondologin'>
        <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

              {/* <div className="d-flex justify-content-center py-4">
                <a href="index.html" className="logo d-flex align-items-center w-auto">
                  <img src="assets/img/LogoCnotas.png" alt="" />
                  <span className="d-none d-lg-block">{config.NOMBRE_APP} {config.VERSION_APP}</span>
                </a>
              </div> */}

              <div className="card mb-3 animate__animated animate__pulse">

              <div className="card-body p-0 auth-header-box">
                                    <div className="text-center p-3">
                                        <a href="index.html" className="logo logo-admin">
                                            <img src="/assets/img/logo-sm.png" height="50" alt="logo" className="auth-logo" />
                                        </a>
                                        <h4 className="mt-3 mb-1 fw-semibold text-white font-18">{config.NOMBRE_APP} {config.VERSION_APP}</h4>   
                                        <p className="text-muted  mb-0">Cofirmar Correo Electrónico</p>  
                                    </div>
                                </div>
                <div className="card-body login">
                    

                    {Confirmado === "1"? 
                    <>
                        <div className='col-md-12 text-center mt-2'>
                            <img src='/assets/img/exito.png' alt='exito' />
                        </div>
                        <div className='col-12'>
                              ¡Hola!<br /><br />
                              Tu dirección de correo electrónico ha sido <strong>confirmada con éxito</strong>. Ahora puedes acceder a todas las funcionalidades de tu cuenta.<br /><br />
                              Gracias por tu paciencia y por completar el proceso de validación.<br /><br />
                              ¡Bienvenido!
                        </div>
                    </>
                  :
                  <>
                      <div className='col-md-12 text-center mt-2'>
                          <img src='/assets/img/error.png' alt='exito' />
                      </div>
                        <div className='col-12'>
                              ¡Hola!<br /><br />
                              Hubo un <strong>problema</strong> al intentar confirmar tu <strong>dirección de correo electrónico</strong>. Puede que el enlace de confirmación haya expirado o sea inválido.<br /><br />
                              Si crees que esto es un error, por favor, contacta con nuestro soporte técnico info@programaresfacil.co para recibir asistencia.<br /><br />
                              ¡Gracias por tu comprensión.!
                        </div>
                    </>
                  }
                     <div className="col-12 text-center mt-2">
                      <p className="small mb-0"><Link to="/">Volver</Link></p>
                    </div> 
                    

                  
                 
                </div>
              </div>

              <div className="credits">
                    Designed by <a href={config.URL_EMPRESA}>Innovación Digital</a>
              </div>

            </div>
          </div>
        </div>

      </section>

    </div>
  </main>
  )
}

export default ConfirmarEmailPage