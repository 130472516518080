import React, { useEffect, useRef, useState } from 'react'
import { formatCurrency, unformatCurrency } from './utils'
import { GetData } from '../services/GetData'
import { PostData } from '../services/PostData'
import BotonTabla from './BotonTabla'

export const FilaFactura = ({ id, eliminarFila, totalfilas, agregarFila,  actualizarFila}) => {
  const [CodigoPro, setCodigoPro] = useState("")  
  const [NombrePro, setNombrePro] = useState("")  
  const [CantPro, setCantPro] = useState(0)  
  const [PrecioVenta, setPrecioVenta] = useState(formatCurrency(0))  
  const [PrecioVentaOr, setPrecioVentaOr] = useState("")  
  const [TotalProducto, setTotalProducto] = useState(0)  
  const [ListaProducto, setListaProducto] = useState([])  
  const [selectedRow, setSelectedRow] = useState(0);

  const cantProRef = useRef(null);
  const nombreProRef = useRef(null);
  const filasRefs = useRef([]); // Array de referenci


  useEffect(() => {
    if (nombreProRef.current) {
        nombreProRef.current.focus();
    }
  }, []);

  const SelProducto = (pro) => {
           if (pro){
              setListaProducto([])
              setCodigoPro(pro.codigo_pro)
              setNombrePro(pro.nombre_pro)
              setCantPro("")
              setPrecioVenta(formatCurrency(pro.precio_venta1))
              setPrecioVentaOr(pro.precio_venta1)
              setTotalProducto(0)        
              cantProRef.current.focus();       
          }
  }

  useEffect(() => {
      setTotalProducto(CantPro * unformatCurrency(PrecioVenta))
      actualizarFila(id, "total", (CantPro * unformatCurrency(PrecioVenta)));
  }, [CantPro])


  const handleKeyUp = (e) => {
    const valor = e.target.value;
    setNombrePro(valor);
  
    if (e.key === 'ArrowDown'){
         focusRow(0)
    }
    if (valor) {
      const datos = {
        nombre: valor
      };
      PostData('/productos/buscar/',datos).then((result) => {
        setListaProducto(result.productos)
      })
    } else {
      setListaProducto([]); // Limpiar la lista si no hay texto
    }
  };

  const handleKeyUpCodigo = (e) => {
    
    const valor = e.target.value;
      
    if (CodigoPro && e.key === 'Enter') {
      const datos = {
        codigo: valor
      };
      PostData('/productos/buscarcodigo/',datos).then((result) => {
              if (result.error === false){
                  const pro = result.producto;
                  setListaProducto([])
                  setCodigoPro(pro.codigo_pro)
                  setNombrePro(pro.nombre_pro)
                  setCantPro("")
                  setPrecioVenta(formatCurrency(pro.precio_venta1))
                  setPrecioVentaOr(pro.precio_venta1)
                  setTotalProducto(0)        
                  cantProRef.current.focus(); 
            }  
      })
    } 
  };
  
  
  const handleKeyUpCant = (e) => {
    if (e.key === 'Enter' && totalfilas === id && parseFloat(CantPro) > 0) {
        actualizarFila(id, "total", TotalProducto);
        agregarFila();
    }
  }

  const handleFocusVenta = () => {
        setPrecioVenta(PrecioVentaOr)
  };

  const handleBlurVenta = () => {
        setPrecioVenta(formatCurrency(PrecioVenta))
  };

const focusRow = (index) => {
  if (filasRefs.current[index]) {
      filasRefs.current[index].focus();
  }
};

// Manejar el evento de teclado
const handleKeyDown = (e, index, pro) => {
  if (e.key === 'ArrowDown') {
    if (index < ListaProducto.length - 1) {
      filasRefs.current[index + 1].focus();
    }
  } else if (e.key === 'ArrowUp') {
    if (index > 0) {
      filasRefs.current[index - 1].focus();
    }
  } else if (e.key === 'Enter'){
        SelProducto(pro)
  }
};


  return (
    <>
         
            <tr>
                    <td>
                        <input type="text" className="form-control form-control-sm campotabla" onKeyUp={(e) => handleKeyUpCodigo(e)} id="codigopro" name="codigopro" value={CodigoPro} onChange={(e) => setCodigoPro(e.target.value)} autocomplete="off" /></td>
                    <td>
                        <input type="text" ref={nombreProRef} className="form-control form-control-sm campotabla" onKe id="nombrepro" name="nombrepro"  onKeyUp={(e) => handleKeyUp(e)} value={NombrePro} onChange={(e) => setNombrePro(e.target.value)} autocomplete="off" />
                          {ListaProducto.length > 0 &&  
                            <div className='listatablapro' onKeyDown={handleKeyDown}>
                                <div className='row listapro-titulo'>
                                    <div className='col-md-7'>Nombre Producto</div>
                                    <div className='col-md-2'>Cant.</div>
                                    <div className='col-md-3'>Precio</div>
                                </div>
                                <div className='listapro_todasfilas'>
                                {ListaProducto.map((pro, index) => 
                                   <div
                                   key={pro.id}
                                   className='row listapro_filas'
                                   tabIndex={0} // Hace que el div sea enfocable
                                   ref={(el) => (filasRefs.current[index] = el)} // Asigna la referencia
                                   onKeyDown={(e) => handleKeyDown(e, index, pro)} // Maneja el evento de teclas
                                 >
                                   <div className='col-md-7' >{pro.nombre_pro}</div>
                                   <div className='col-md-2'>{pro.existencia}</div>
                                   <div className='col-md-3'>{formatCurrency(pro.precio_venta1)}</div>
                                 </div>                             
                                )}
                                </div>
                               {/*  <table class="table table-hover ">
                                <thead className='listadesple'>
                                    <tr>
                                        <th scope="col">Nombre Producto</th>
                                        <th scope="col">Cant.</th>
                                        <th scope="col">Precio</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {ListaProducto.map((pro) => 
                                        <tr ref={addRowRef} key={pro.id_producto} onClick={()=> SelProducto(pro)}>
                                            <td width="70%">{pro.nombre_pro}</td>                               
                                            <td width="10%">{pro.existencia}</td>                               
                                            <td width="20%">{formatCurrency(pro.precio_venta1)}</td>
                                                                          
                                        </tr>
                                        )}                                  
                                    </tbody>
                                </table>    */}
                            </div>
                            } 
                    </td>
                    <td><input type="text" ref={cantProRef}  onKeyUp={(e) => handleKeyUpCant(e)}  className="form-control form-control-sm campotabla campotabla text-center" id="cantpro" name="cantpro" value={CantPro} onChange={(e) => setCantPro(e.target.value)} /></td>
                    <td>
                        <input type="text"  onKeyUp={(e) => handleKeyUpCant(e)} 
                               className="form-control form-control-sm campotabla campotabla" id="preciopro" name="cantpro" 
                               value={PrecioVenta} 
                               onFocus={handleFocusVenta}
                               onBlur={handleBlurVenta}
                               onChange={(e) => setPrecioVenta(e.target.value)} />
                        <div></div>
                    </td>
                    <td>{formatCurrency(TotalProducto)}</td>
                    <td>
                      <BotonTabla icono='borrar.png' titulo='Eliminar' evento={()=>eliminarFila(id)} />
                    </td> 
            </tr>
    </>
  )
}
