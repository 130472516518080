import React, { useContext, useEffect, useState } from 'react'
import { PostData } from '../../services/PostData'
import Swal from 'sweetalert2';
import { SessionContext } from '../../SessionContext';
import config from '../../config';
import 'animate.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { Link, useNavigate } from 'react-router-dom';

const RegisterPage = () => {

  const [Email, setEmail] = useState("")
  const [Password, setPassword] = useState("")
  const [Nombre, setNombre] = useState("")
  const [Sede, setSede] = useState(null)
  const { setUser } = useContext(SessionContext);
  const navigate = useNavigate();

  useEffect(() => {
      localStorage.setItem('URL_SERVICIOS', config.API_URL_PPAL);   
  }, [])
 
  

  const login = (e) => {
      e.preventDefault();

      const datos = {
            email: Email,
            password: Password,
            nombre: Nombre
      }

        PostData('login/registrarse', datos).then ((result) =>{
          let responseJSON = result;
          if (responseJSON.error === false){
              // setUser(result.usuario); 
              
               Swal.fire({
                title: 'Usuario creado',
                text: responseJSON.mensaje,
                icon: 'success',
                confirmButtonText: 'Aceptar'
              });
              navigate("/");
           }else{
            Swal.fire({
              title: 'Usuario no válidos',
              text: responseJSON.mensaje,
              icon: 'error',
              confirmButtonText: 'Aceptar'
            });
            
        }
      })

  }

  return (
  <main className='fondologin'>
        <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

              {/* <div className="d-flex justify-content-center py-4">
                <a href="index.html" className="logo d-flex align-items-center w-auto">
                  <img src="assets/img/LogoCnotas.png" alt="" />
                  <span className="d-none d-lg-block">{config.NOMBRE_APP} {config.VERSION_APP}</span>
                </a>
              </div> */}

              <div className="card mb-3 animate__animated animate__pulse">

              <div className="card-body p-0 auth-header-box">
                                    <div className="text-center p-3">
                                        <a href="index.html" className="logo logo-admin">
                                            <img src="assets/img/logo-sm.png" height="50" alt="logo" className="auth-logo" />
                                        </a>
                                        <h4 className="mt-3 mb-1 fw-semibold text-white font-18">{config.NOMBRE_APP} {config.VERSION_APP}</h4>   
                                        <p className="text-muted  mb-0">Registrarse</p>  
                                    </div>
                                </div>
                <div className="card-body login">

                  <form className="row g-3 needs-validation"  onSubmit={login}>
                    <div className="col-12 mt-4">
                      <label htmlFor="nombreusuario" className="form-label label">Nombres</label>
                      <div className="input-group has-validation">
                        <input type="text" name="nombre" 
                            className="form-control form-control-sm" style={{  color: '#004085', fontWeight: 'bold' }} id="nombreusuario" required  
                            onChange={e => setNombre(e.target.value)} />
                        <div className="invalid-feedback">Please enter your username.</div>
                      </div>
                    </div>
                    <div className="col-12">
                      <label htmlFor="email" className="form-label label">Correo Electrónico</label>
                      <div className="input-group has-validation">
                        <input type="text" name="Email" 
                            className="form-control form-control-sm" style={{  color: '#004085', fontWeight: 'bold' }} id="email" required  
                            onChange={e => setEmail(e.target.value)} autocomplete="off" />
                        <div className="invalid-feedback">Please enter your username.</div>
                      </div>
                    </div>

                    <div className="col-12">
                      <label htmlFor="yourPassword" className="form-label label">Contraseña</label>
                      <input type="password" name="Password" className="form-control form-control-sm" style={{  color: '#004085', fontWeight: 'bold' }} id="yourPassword" required  onChange={e => setPassword(e.target.value)} autocomplete="off" /> 
                      <div className="invalid-feedback">Please enter your password!</div>
                    </div>
                    <div className="col-12">
                      <label htmlFor="yourPassword" className="form-label label">Confirmar Contraseña</label>
                      <input type="password"  name="ConfirmarPassword" className="form-control form-control-sm" style={{  color: '#004085', fontWeight: 'bold' }} id="yourPassword" required  onChange={e => setPassword(e.target.value)} autocomplete="off" /> 
                      <div className="invalid-feedback">Please enter your password!</div>
                    </div>

                   
                    <div className="col-12">
                      <button className="btn btn-primary w-100" type="submit" disabled={!Email || !Password || !Nombre}>Registrarse <i className="bi bi-box-arrow-in-right"></i></button>
                    </div>
                     <div className="col-12 text-center">
                      <p className="small mb-0"><Link to="/">Volver al Inicio</Link></p>
                    </div> 
                    

                  </form>
                 
                </div>
              </div>

              <div className="credits">
                    Designed by <a href={config.URL_EMPRESA}>Innovación Digital</a>
              </div>

            </div>
          </div>
        </div>

      </section>

    </div>
  </main>
  )
}

export default RegisterPage