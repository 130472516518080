// config.js
const config = {
    LOGO: '/asset/img/logo-sm.png',
    VERSION_APP: '1.0',
    NOMBRE_APP: 'Cactus Web',
    API_URL_PPAL: 'https://apicactus.programaresfacil.co/index.php/',
    API_URL_PATH: 'https://apicactus.programaresfacil.co/',
    EMPRESA: 'Innovacion Digital',
    URL_EMPRESA: 'https://innovaciondigitalsoft.com/'
  };
  
  export default config;
  