import React, { useEffect, useState } from 'react'
import { GetData } from '../services/GetData'
import { AnioActual, formatCurrency, formatDateCorto, MesActual } from '../components/utils'
import ApexCharts from 'react-apexcharts';

const HomePrincipal = () => {
    const [NumProd, setNumProd] = useState(0)
    const [NumProveedores, setNumProveedores] = useState(0)
    const [NumClientes, setNumClientes] = useState(0)
    const [NumDoc, setNumDoc] = useState(0)
    const [Periodo, setPeriodo] = useState(0)
    const [ValorInventario, setValorInventario] = useState(0)
    const [TotalVentaMes, setTotalVentaMes] = useState(0)
    const [FechaCierre, setFechaCierre] = useState(null)
    const [TiempoRestante, setTiempoRestante] = useState("")
    const [ListaVentasMes, setListaVentasMes] = useState([])
    const [ListaVentasMeses, setListaVentasMeses] = useState([])

    const mes = MesActual();
    const anio = AnioActual();  
      

    const meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Agos", "Sep", "Oct", "Nov", "Dic"];

     useEffect(() => {
         GetData('empresas/statusempresa', null).then ((result) =>{
            let responseJSON = result;

            if (responseJSON.error === false){
                setNumProd(result.num_productos)
                setNumProveedores(result.num_proveedor)
                setNumClientes(result.num_clientes)
                setValorInventario(result.valor_inventario)   
                setTotalVentaMes(result.ventas_mes)   
            }
        })
        
        GetData('arqueoscaja/listadomesanio/'+ mes +'/' + anio, null).then ((result) =>{
            let responseJSON = result;

            if (responseJSON.error === false){
                setListaVentasMes(result.dias)
                setListaVentasMeses(result.meses)
                
            }
        }) 
    }, []) 
    

    const procesarDatosParaGrafico = () => {
        // Extrae las fechas y las ventas totales
        const ventasTotales = ListaVentasMes.map(item => parseFloat(item.ventas_contado) + parseFloat(item.ventas_credito));


        return {
            series: [
                {
                    name: 'Ventas Totales',
                    data: ventasTotales
                }
            ],
            options: {
                chart: {
                    type: 'bar',
                    height: 350
                },
                plotOptions: {
                    bar: {
                        dataLabels: {
                            position: 'top' // Posición de las etiquetas: 'top', 'center', 'bottom'
                        }
                    }
                },
                dataLabels: {
                    enabled: false // Desactiva las etiquetas en las columnas
                },
                xaxis: {
                    categories: ListaVentasMes.map(venta => formatDateCorto(venta.fecha_arq)),
                    position: 'bottom',
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            return formatCurrency(value.toFixed(0)); // Si deseas que los valores en la orilla se muestren sin decimales
                        }
                    }
                },
                title: {
                    text: 'Ventas del Mes',
                    floating: true,
                    offsetY: 320,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                }
            }
        };
    };

    const DatosParaGraficoMeses = () => {
        // Extrae las fechas y las ventas totales
        const ventasTotales = ListaVentasMeses.map(item => parseFloat(item.total_ventas));


        return {
            series: [
                {
                    name: 'Ventas Totales',
                    data: ventasTotales
                }
            ],
            options: {
            chart: {
                type: 'bar',
                height: 350
            },
            plotOptions: {
                bar: {
                    borderRadius: 10,
                    dataLabels: {
                        position: 'top'
                    }
                }
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                categories: ListaVentasMeses.map(d => meses[d.mes - 1]), // Muestra los meses en el eje X
                position: 'top',
                crosshairs: {
                    fill: {
                      type: 'gradient',
                      gradient: {
                        colorFrom: '#D8E3F0',
                        colorTo: '#BED1E6',
                        stops: [0, 100],
                        opacityFrom: 0.4,
                        opacityTo: 0.5,
                      }
                    }
                  },
            },
            yaxis: {
                labels: {
                    formatter: function (value) {
                        return value.toFixed(0);
                    }
                }
            },
            title: {
                text: 'Ventas del Mes',
                floating: true,
                offsetY: 320,
                align: 'center',
                style: {
                    color: '#444'
                }
            }
            }            
        };
    };


  return (
    <>
        <section className="section dashboard">
            <div className="row">
        
            
            <div className="col-lg-12">
                <div className="row">
        
            
                <div className="col-xxl-3 col-md-4">
                    <div className="card info-card sales-card">
        
        
                    <div className="card-body">
                        <h5 className="card-title">Inventario <span>| Productos</span></h5>
        
                        <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                            <i className="bi bi-people"></i>
                        </div>
                        <div className="ps-3">
                            <h6>{NumProd}</h6>
                           {/*  <span className="text-success small pt-1 fw-bold">12%</span> <span className="text-muted small pt-2 ps-1">increase</span> */}
        
                        </div>
                        </div>
                    </div>
        
                    </div>
                </div>
        
                {/* <!-- Revenue Card --> */}
                <div className="col-xxl-3 col-md-4">
                    <div className="card info-card revenue-card">
        
        
                    <div className="card-body">
                        <h5 className="card-title">Clientes <span>| </span></h5>
        
                        <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                            <i className="bi bi-person-dash"></i>
                        </div>
                        <div className="ps-3">
                            <h6>{NumClientes}</h6>
                           {/*  <span className="text-success small pt-1 fw-bold">8%</span> <span className="text-muted small pt-2 ps-1">increase</span> */}
        
                        </div>
                        </div>
                    </div>
        
                    </div>
                </div>
                {/* <!-- End Revenue Card --> */}
        
                {/*  <!-- Customers Card --> */}
                <div className="col-xxl-3 col-md-4">
        
                    <div className="card info-card customers-card">
        
                   
                    <div className="card-body">
                        <h5 className="card-title">Proveedores <span></span></h5>
        
                        <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                            <i className="bi bi-person-badge"></i>
                        </div>
                        <div className="ps-3">
                            <h6>{NumProveedores}</h6>
                           {/*  <span className="text-danger small pt-1 fw-bold">12%</span> <span className="text-muted small pt-2 ps-1">decrease</span> */}
        
                        </div>
                        </div>
        
                    </div>
                    
                    </div>
        
                </div>
                {/* <!-- End Customers Card --> */}

                {/*  <!-- Customers Card --> */}
                <div className="col-xxl-3 col-md-4">
        
                    <div className="card activo-card customers-card">
        
                    
        
                    <div className="card-body">
                        <h5 className="card-title">Ventas <span>| Mes </span></h5>
        
                        <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                            <i className="bi bi-cart-check-fill"></i>
                        </div>
                        <div className="ps-3">
                            <h6>{formatCurrency(TotalVentaMes)}</h6>
                            <span className="text-danger small pt-1 fw-bold">{FechaCierre}</span> <span className="text-muted small pt-2 ps-1">{TiempoRestante}</span>
        
                        </div>
                        </div>
                        
        
                    </div>
                    
                    </div>
        
                </div>

                <div className="col-xxl-3 col-md-4">
        
                    <div className="card info-card revenue-card">
        
                    
        
                    <div className="card-body">
                        <h5 className="card-title">Total <span>| Inventario</span></h5>
        
                        <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                            <i className="bi bi-stopwatch"></i>
                        </div>
                        <div className="ps-3">
                            <h6>{formatCurrency(ValorInventario)}</h6>
                            <span className="text-danger small pt-1 fw-bold"></span> 
                            <span className="text-muted small pt-2 ps-1"></span>
        
                        </div>
                        </div>
                        
        
                    </div>
                    
                    </div>
        
                </div>
                {/* <!-- End Customers Card --> */}

                {/*  <!-- Customers Card --> */}
                
                {/* <!-- End Customers Card --> */}
                <div className="row">
                <div className='card'>
                    <div className="card-body">
                        {ListaVentasMes.length > 0 && (
                            <ApexCharts
                                options={procesarDatosParaGrafico().options}
                                series={procesarDatosParaGrafico().series}
                                type="bar"
                                height={350}
                            />
                        )}
                        </div>
                    </div>
                <div className='card'>
                    <div className="card-body">
                        {ListaVentasMeses.length > 0 && (
                            <ApexCharts
                                options={DatosParaGraficoMeses().options}
                                series={DatosParaGraficoMeses().series}
                                type="bar"
                                height={350}
                            />
                        )}
                        </div>
                    </div>
                </div>
           
                    {/* <!-- End sidebar recent posts--> */}
        
             
                {/* <!-- End News & Updates --> */}
        
            </div>
            {/* <!-- End Right side columns --> */}
        
            </div>
            </div>
        </section>
  </>
  )
}

export default HomePrincipal