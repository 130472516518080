import { Link, NavLink } from "react-router-dom"
import { Usuario } from "../../services/Usuario";

const SideBar = () => {

    const usuario = Usuario();
    const tipousario = parseInt(usuario.id_tipo_usuario)
    
    const cerrarMenu = () => {
            document.body.classList.remove('toggle-sidebar');
    }

  return (
    <aside id="sidebar" className="sidebar">

        <ul className="sidebar-nav" id="sidebar-nav">

        <li className="nav-item">
            <NavLink className="nav-link collapsed" to="/home/principal" activeClassName="active" onClick={()=>cerrarMenu()} >
            <i className="bi bi-grid"></i>
            <span>Dashboard</span>
            </NavLink>
        </li>
        
        <li className="nav-item">
            <Link className="nav-link collapsed" data-bs-target="#components-nav" data-bs-toggle="collapse" href="#">
            <i className="bi bi-gear"></i><span>Administrativo</span><i className="bi bi-chevron-down ms-auto"></i>
            </Link>
            <ul id="components-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
               {/*  <li>
                    <NavLink to={"/home/listadousuarios"} className="nav-link collapsed" onClick={()=>cerrarMenu()} >
                    <i className="bi bi-circle"></i><span>Administrar Usuarios</span>
                    </NavLink>
                </li> */}
                <li className="nav-item ">            
                    <NavLink to={"/home/categorias"} className="nav-link collapsed" activeClassName="active" onClick={()=>cerrarMenu()} >
                    <i className="bi bi-bar-chart-steps"></i>
                    <span>Categorías</span>
                    </NavLink>
                </li>       
                <li className="nav-item ">            
                    <NavLink to={"/home/clientes"} className="nav-link collapsed" activeClassName="active" onClick={()=>cerrarMenu()}>
                    <i className="bi bi-file-person"></i>
                    <span>Clientes</span>
                    </NavLink>
                </li>     
                <li className="nav-item ">            
                    <NavLink to={"/home/proveedores"} className="nav-link collapsed" activeClassName="active" onClick={()=>cerrarMenu()} >
                    <i className="bi bi-person-plus"></i>
                    <span>Proveedores</span>
                    </NavLink>
                </li>     
                <li className="nav-item ">            
                    <NavLink to={"/home/productos"} className="nav-link collapsed" activeClassName="active" onClick={()=>cerrarMenu()} >
                    <i className="bi bi-folder2-open"></i>
                    <span>Inventario</span>
                    </NavLink>
                </li>       
                <li className="nav-item ">            
                    <NavLink to={"/home/cierrecaja"} className="nav-link collapsed" activeClassName="active" onClick={()=>cerrarMenu()} >
                    <i className="bi bi-folder2-open"></i>
                    <span>Cierres de Caja</span>
                    </NavLink>
                </li>       
                  
               
                           
                </ul>
            </li>
            <NavLink to={"/home/listadoprecios"} className="nav-link collapsed" onClick={()=>cerrarMenu()} >
                    <i className="bi bi-card-list"></i><span>Lista de Precios</span>
            </NavLink>
            <li className="nav-item">
            <Link className="nav-link collapsed" data-bs-target="#components-cart" data-bs-toggle="collapse" href="#" onClick={()=>cerrarMenu()} >
            <i className="bi bi-wallet-fill"></i><span>Cartera</span><i className="bi bi-chevron-down ms-auto"></i>
            </Link>
            <ul id="components-cart" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                <li>
                    <NavLink to={"/home/carteraclientes"} className="nav-link collapsed" onClick={()=>cerrarMenu()} >
                    <i className="bi bi-person-lines-fill"></i><span>Cartera de Clientes</span>
                    </NavLink>
                </li>
            </ul>
            </li>
       
       

        </ul>

    </aside>
  )
}

export default SideBar