import SecureLS from 'secure-ls';
import moment from "moment";

const ls = new SecureLS({ encodingType: 'aes', isCompression: false });

export const saveToStorage = (key, value) => {
  try {
    ls.set(key, value);
  } catch (error) {
    console.error('Error al guardar en localStorage seguro:', error);
  }
};

export const getFromStorage = (key) => {
  try {
    return ls.get(key);
  } catch (error) {
    console.error('Error al obtener desde localStorage seguro:', error);
    return null;
  }
};

export const removeFromStorage = (key) => {
  try {
    ls.remove(key);
  } catch (error) {
    console.error('Error al eliminar desde localStorage seguro:', error);
  }
};



export const validarEmail = (email) => {
    // Implementa la lógica de validación de correo electrónico aquí
    // Por ejemplo, verifica si el correo electrónico tiene un formato válido
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
  };

  export const calculateEdad = (dateOfBirth) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age;
};

export const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const dd = String(today.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
}

export const obtenerMesActual = () => {
    const fechaActual = new Date();
    const mesActual = fechaActual.getMonth() + 1; // Los meses en JavaScript son 0-indexados, por lo que se suma 1
    return mesActual;
  };

export const formatCurrency = (value) => {
    return new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0, maximumFractionDigits: 0  }).format(value);
  };

  //quita el formato moneta
  export const unformatCurrency = (formattedValue) => {
    return parseFloat(formattedValue.replace(/[^0-9]/g, ""), 10);
};


export const formatDate = (value) => {
    //return moment(value).subtract(5, 'hours').format('DD-MM-YYYY HH:mm:ss');
    return moment(value).format('DD-MM-YYYY HH:mm:ss');
  };
export const formatSoloDate = (value) => {
    //return moment(value).subtract(5, 'hours').format('DD-MM-YYYY HH:mm:ss');
    return moment(value).format('DD-MM-YYYY');
  };
export const MesActual = () => {
      const today = new Date();
      const yyyy = today.getFullYear();
      const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      return mm;
  };
export const AnioActual = () => {
      const today = new Date();
      const yyyy = today.getFullYear();

      return yyyy;
  };

export const formatDateCorto = (value) => {
    //return moment(value).subtract(5, 'hours').format('DD-MM-YYYY HH:mm:ss');
    if (value)
        return moment(value).format('DD-MM');
    else
        return ''  
  };


export const mayusculaIniciales = (text) => {
    return text.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
}
