import React, { useEffect, useState } from 'react'
import { FilaFactura } from '../components/FilaFactura'
import { formatCurrency } from '../components/utils'

const PageCrearFactura = () => {

    const [Termino, setTermino] = useState(0)
    const [Dias, setDias] = useState(0)
    const [Imprimir, setImprimir] = useState(false)
    const [Vendedor, setVendedor] = useState(0)

    const [CodigoCli, setCodigoCli] = useState(0)
    const [NombreCli, setNombreCli] = useState("")
    const [TelefonoCli, setTelefonoCli] = useState("")
    const [TotalFactura, setTotalFactura] = useState(0)

    const [filas, setFilas] = useState([{ id: 1, codigo: '', nombre: '', cantidad: 0, precio: 0, total: 0 }]);

    const agregarFila = () => {
        setFilas([...filas, { id: filas.length + 1, codigo: '', nombre: '', cantidad: 0, precio: 0, total: 0 }]);
    };

    const eliminarFila = (id) => {
        setFilas(filas.filter(fila => fila.id !== id));
    };

    // Función para actualizar los datos de una fila
    const actualizarFila = (id, campo, valor) => {
        const nuevasFilas = filas.map((fila) => {
        if (fila.id === id) {
            const nuevaFila = { ...fila, [campo]: valor };
            nuevaFila.total =+ nuevaFila.total;
            return nuevaFila;
        }
        return fila;
        });
        setFilas(nuevasFilas);
    };

    // Efecto para recalcular el total de la factura
    useEffect(() => {
        const total = filas.reduce((acc, fila) => acc + fila.total, 0);
        setTotalFactura(total);
    }, [filas]);

    const NuvaFactura = () => {

    }

  return (
    <>
          <section className='section dashboard'>
            <div className="card">
            <div className='card-header'>Crear Factura de venta</div>    
            <div className="card-body pb-0">
                    
                    <div className='row'>
                            <div className='col-md-2'>
                                <button type="button" className="btn btn-primary btn-sm mt-4 ml-2" onClick={NuvaFactura} >
                                    <i className="bi bi-person-plus"></i> Nuevo Factura
                                </button>
                            </div>
                    </div>    
                    <div className='row'>                          
                            <div className='col-md-2'>
                                     <span className='label'>Tipo Fact:</span>
                                     <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={Termino} onChange={(e) => setTermino(e.target.value)} >
                                          <option  value="">Seleccionar</option>
                                          <option  value={0}>Contado</option>
                                          <option  value={1}>Crédito</option>                                    
                                    </select> 
                            </div>
                            <div className='col-md-1'>                                    
                                    <span className='label'>Dias:</span>
                                    <input type="number" className="form-control form-control-sm estilocampo" id="dias" name="dias" value={Dias} onChange={(e) => setDias(e.target.value)} />
                            </div>
                            <div className='col-md-1'>
                                    <span className='label'>Imprimir:</span>
                                    <input type="checkbox" className="form-check-input" id="servicio_pro" name="servicio_pro" checked={Imprimir} onChange={(e) => setImprimir(e.target.checked)} />
                            </div>
                            <div className='col-md-3'>
                                    <span className='label'>Vendedor:</span>
                                    <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={Vendedor} onChange={(e) => setVendedor(e.target.value)} >
                                          <option  value="">Seleccionar</option>
                                    </select> 
                            </div>
                            <div className='col-md-2'>
                                <span className='total_fact'>{formatCurrency(TotalFactura)}</span>
                            </div>
                           
                    </div>   
                    
                    <div className='row mt-2 border-frame'>    
                            <span className='label'>Cliente</span>
                            
                                <div className='col-md-2'>
                                        <span className='label'>Código:</span>
                                        <input type="text" className="form-control form-control-sm estilocampo" id="dias" name="dias" value={CodigoCli} onChange={(e) => setCodigoCli(e.target.value)} />
                                </div>    
                                <div className='col-md-4'>
                                        <span className='label'>Nombre Cliente:</span>
                                        <input type="text" className="form-control form-control-sm estilocampo" id="dias" name="dias" value={CodigoCli} onChange={(e) => setCodigoCli(e.target.value)} />
                                </div>    
                                <div className='col-md-2'>
                                        <span className='label'>Telefono:</span>
                                        <input type="text" className="form-control form-control-sm estilocampo" id="dias" name="dias" value={CodigoCli} onChange={(e) => setCodigoCli(e.target.value)} />
                                </div>    
                                <div className='col-md-1 mt-3'>
                                    <button type="button" class="btn btn-info rounded-pill"><i class="bi bi-search"></i></button>
                                </div>
                            
                    </div> 
                    <button type="button" className="btn btn-primary btn-sm mt-4 ml-2" onClick={agregarFila} >
                                    <i className="bi bi-person-plus"></i> +
                                </button>    
                    <div className='row'>
                        <div className="table-responsive">
                            <table class="table table-striped  mt-2">
                                <thead>
                                <tr>
                                    <th scope="col" width="10%">Código</th>
                                    <th scope="col"  width="60%">Nombre Producto</th>
                                    <th scope="col" width="5%">Cant.</th>
                                    <th scope="col" width="15%">Precio</th>
                                    <th scope="col" width="15%">Total Pro</th>
                                    <th scope="col" width="15%">Op</th>
                                </tr>
                                </thead>
                                <tbody>
                                {filas.map((fila, index) => (
                                    <FilaFactura 
                                        key={fila.id} 
                                        id={fila.id} 
                                        totalfilas={filas.length}
                                        eliminarFila={eliminarFila} 
                                        agregarFila={agregarFila}
                                        actualizarFila={actualizarFila}
                                    />
                                    ))}
                                </tbody>
                            </table>
                         </div>
                    </div>
            </div>
            </div>
        </section>                
    </>
  )
}

export default PageCrearFactura