// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
/*  const firebaseConfig = {
  apiKey: "AIzaSyBn9Wpsjza_t7Sjz5XbeYkFlsrOgYF6Kzk",
  authDomain: "contaweb-5b437.firebaseapp.com",
  projectId: "contaweb-5b437",
  storageBucket: "contaweb-5b437.appspot.com",
  messagingSenderId: "364543808734",
  appId: "1:364543808734:web:5e09bd52cd7241a5255e4f",
  measurementId: "G-JCK6HVLFK5"
};  */
const firebaseConfig = {
  apiKey: "AIzaSyCg0da4-N4uhnTTYIF_cN1MA_C-TCfGp28",
  authDomain: "app-cactus-web.firebaseapp.com",
  projectId: "app-cactus-web",
  storageBucket: "app-cactus-web.appspot.com",
  messagingSenderId: "561075071266",
  appId: "1:561075071266:web:c13ed563816a3f068edd7b",
  measurementId: "G-WCP14WZCKH"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

export { auth, googleProvider, facebookProvider };