import React, { useEffect, useState } from 'react'
import { formatCurrency } from '../components/utils'
import { GetData } from '../services/GetData'



const PageListaPrecios = () => {
    const [Loading, setLoading] = useState(false)
    const [Refrest, setRefrest] = useState(false)
    const [ListadoProductos, setListadoProductos] = useState([])    
    const [ListaFiltrada, setListaFiltrada] = useState([])
    const [FiltrarPor, setFiltrarPor] = useState(1)
    const [Filtrar, setFiltrar] = useState('')
    const [mostrarAgotados, setMostrarAgotados] = useState(false);

    useEffect(() => {
        setLoading(true)
        GetData('productos').then((result) => {
                if (result.error === false){
                    setListadoProductos(result.productos)
                    setListaFiltrada(result.productos);
                }
                setLoading(false)
        })
    }, [Refrest])
    

    useEffect(() => {
        let listaFiltrada = ListadoProductos;
      
        if (Filtrar) {
          listaFiltrada = listaFiltrada.filter(
            (alumno) =>
              alumno.nombre_pro && alumno.nombre_pro.toLowerCase().includes(Filtrar.toLowerCase())
          );
        }
      
        if (mostrarAgotados) {
            listaFiltrada = listaFiltrada.filter(
              (producto) => parseFloat(producto.existencia) === 0
            );
        }

        setListaFiltrada(listaFiltrada);
      
      
      }, [Filtrar, mostrarAgotados])


  return (
    <>
     <section className='section dashboard listap'>
            <div class="card">
                  
                <div className="card-body pb-0">
                    <h5 className="card-title">Lista de Precios</h5>

                    <div className='row'>                          
                            <div className='col-md-4'>  
                               <span className='label'>Filtrar</span> 
                                <input type="text" style={{ backgroundColor: '#eaf2ff', color: '#004085', fontWeight: 'bold' }} 
                                  className="form-control form-control-sm" id="fnombres" name="fnombres" value={Filtrar} onChange={(e) => setFiltrar(e.target.value)} />                                  
                            </div>                           
                            <div  className='col-md-2 mt-4'>
                                    <span className='label'> N° Productos:</span> <span className='texto'>{ListadoProductos.length}</span> 
                            </div>
                            <div className='col-md-2'>
                                  <button type="button" class="btn btn-info mt-4" onClick={()=> setRefrest(!Refrest)}>Refrescar</button>
                            </div>
                            <div className='col-md-2'>
                                <div class="form-check mt-4">
                                    <input class="form-check-input" type="checkbox" id="gridCheck2" checked={mostrarAgotados}
                                     onChange={(e) => setMostrarAgotados(e.target.checked)}  />
                                    <label class="form-check-label" for="gridCheck2">
                                        Agotados
                                    </label>
                                </div>
                            </div>
                    </div>    

                    {Loading && 
                    <div class="progress mt-3">
                        <div class="progress-bar progress-bar-striped bg-info progress-bar-animated" role="progressbar" style={{width : '100%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>      
                    }      
                    <div className="table-responsive">
                    <table class="table table-striped  mt-2">
                        <thead>
                        <tr>
                            <th scope="col">Nombre</th>
                            <th scope="col">Exist.</th>
                            <th scope="col">Precio</th>
                            <th scope="col">P. Mín</th>
                        </tr>
                        </thead>
                        <tbody>
                        {ListaFiltrada.map((pro, index) => 
                        <tr index={pro.id_producto}>
                            <td>{pro.nombre_pro}</td>
                            <td align='center'>{pro.existencia}</td>
                            <td>{formatCurrency(pro.precio_costo)}</td>
                            <td>{formatCurrency(pro.precio_venta_min)}</td>
                        </tr>
                        )}    
                        
                        </tbody>
                    </table>
                    </div>
                    </div>
                </div>
            </section>    

           
    </>
  )
}

export default PageListaPrecios