import React, { useEffect, useState } from 'react'
import MensajeError from '../components/MensajeError'
import { GetData } from '../services/GetData'
import { PostData } from '../services/PostData'
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PageDatosEmpresa = () => {

   const [id_empresa, setId_empresa] = useState(0) 
   const [nombre_empresa, setNombre_empresa] = useState("") 
   const [nit_empresa, setNit_empresa] = useState("") 
   const [telefono_empresa, setTelefono_empresa] = useState("") 
   const [direccion_empresa, setDireccion_empresa] = useState("") 
   const [mensaje_empresa, setMensaje_empresa] = useState("") 
   const [Token, setToken] = useState("") 
   const [btnToken, setbtnToken] = useState(false) 

   useEffect(() => {
    GetData('empresas/empresainicial', null).then ((result) =>{
      if (result.error === false){
          const empresa = result.empresa;
          setId_empresa(empresa.id_empresa)
          setNombre_empresa(empresa.nombre_empresa);
          setNit_empresa(empresa.nit_empresa);
          setTelefono_empresa(empresa.telefono_empresa);
          setDireccion_empresa(empresa.direccion_empresa);
          setMensaje_empresa(empresa.mensajecobro1);
          setToken(empresa.token_access);

          //setEmpresaActual(result.empresa);
      }
  })
    
  }, [])

   const Guardar = () => {
      const data = {
        id_empresa,
        nombre_empresa,
        nit_empresa,
        telefono_empresa,
        direccion_empresa,
        mensajecobro1: mensaje_empresa
      }
      PostData('empresas/save', data).then((result) => {
          if (result.error === false){
            Swal.fire({
              title: 'Guardar Empresa',
              text: result.mensaje,
              icon: 'success',
              confirmButtonText: 'Aceptar'
            });
            window.location.reload();
          }else{
            Swal.fire({
              title: 'Guardar Empresa',
              text: result.mensaje,
              icon: 'error',
              confirmButtonText: 'Aceptar'
            });
          }
      })
   }

   const generarToken = () => {

      GetData('empresas/generartoken', null).then ((result) =>{
            setToken(result.token)
            setbtnToken(true)
      });
   }

   const copyToClipboard = () => {
    if (Token) {
      navigator.clipboard.writeText(Token).then(() => {
        toast.success('Token copiado al portapapeles', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      }).catch(err => {
        console.error('Error al copiar el token: ', err);
      });
    }
  };

  return (
    <>
       <section className='section dashboard'>
            <div className="card pb-2">
            <div className='card-header'>Datos de la Empresa</div>    
            <div className="card-body pb-0">

                <div className="formulario">
                    <form method="post" >

                    <div className='row'>
                        <div className='col-md-3'>

                        </div>
                        <div className='col-md-9'>

                              <div className='col-md-12'>
                                      <span className='label'>Nombre de la Empresa: </span> {!nombre_empresa && MensajeError('Debe llenar el campo')}
                                      <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={nombre_empresa} onChange={(e) => setNombre_empresa(e.target.value)} />
                              </div>   
                          
                          
                              <div className='col-md-6'>
                                      <span className='label'>Nit : </span> {!nit_empresa && MensajeError('Debe llenar el campo')}
                                      <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={nit_empresa} onChange={(e) => setNit_empresa(e.target.value)} />
                              </div>     
                              <div className='col-md-6'>
                                      <span className='label'>Teléfono: </span> {!telefono_empresa && MensajeError('Debe llenar el campo')}
                                      <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={telefono_empresa} onChange={(e) => setTelefono_empresa(e.target.value)} />
                              </div>     
                              <div className='col-md-12'>
                                      <span className='label'>Dirección: </span> {!direccion_empresa && MensajeError('Debe llenar el campo')}
                                      <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={direccion_empresa} onChange={(e) => setDireccion_empresa(e.target.value)} />
                              </div>     

                              <div className='col-md-12'>
                                      <span className='label'>Mensaje Whatsapp: </span>  {!mensaje_empresa && MensajeError('Debe llenar el campo')}
                                      <textarea 
                                            className="form-control form-control-sm estilocampo" 
                                            rows={4} 
                                            value={mensaje_empresa} 
                                            onChange={(e) => setMensaje_empresa(e.target.value)}
                                        />
                                       <span className="label">
                                            Construya el texto de cobro teniendo en cuenta estas variables: ${'nombre'}, ${'saldo'}, ${'empresa'}
                                        </span>
                              </div>     
                        
                              <div className='col-md-12 mt-3'>
                                  <button type="button" className="btn btn-primary btn-sm" onClick={Guardar} ><i className="bi bi-clipboard-plus"></i> Guardar</button>  
                              </div>

                              <div className='col-md-6 mt-3'>
                                  <div class="alert alert-info alert-dismissible fade show" role="alert">
                                      Genere el token para el acceso a la api. Esto le permitira subir productos, cliente, proveedores.
                                  </div>
                                <span></span>
                              </div>
                              <div className='col-md-6 mt-3'>
                                      <span className='label'>API Token: </span>
                                      <input type="text"  className="form-control form-control-sm estilocampo" id="token" name="token" onClick={copyToClipboard} value={Token} onChange={(e) => setToken(e.target.value)} readOnly  />
                              </div> 
                              <div className='col-md-12 mt-2'>
                                      <button type="button" disabled={btnToken} className="btn btn-primary btn-sm" onClick={generarToken} ><i className="bi bi-clipboard-plus"></i> Generar</button>  
                              </div> 

                        </div> {/* fin col-md-9 */}
                    
                    

                   
                    </div>
              


                    </form>
                </div>
            </div>
       </div>
       </section>             
       <ToastContainer />
    </>
  )
}

export default PageDatosEmpresa