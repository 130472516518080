import React, { useEffect, useState } from 'react'
import { GetData } from '../services/GetData'
import { formatCurrency, mayusculaIniciales } from '../components/utils'


const PageCarteraClientes = () => {
    const [ListaClientes, setListaClientes] = useState([])
    const [DatosEmpresa, setDatosEmpresa] = useState([])
    const [TotalCartera, setTotalCartera] = useState(0)
    const [Loading, setLoading] = useState(false)
    const [Refrest, setRefrest] = useState(false)


  
    useEffect(() => {
      
        GetData('empresas/datosempresa').then((result) => {
            if (result.error === false){
                setDatosEmpresa(result.datos)
            }
        })
        

    }, [])
    

    const Mensaje = (Nombre, Saldo) => {
        let mensaje = "";
        if (DatosEmpresa.mensajecobro1){
            mensaje = DatosEmpresa.mensajecobro1;
            const empresa = DatosEmpresa.nombre_empresa;
            mensaje = mensaje.replace("${nombre}", Nombre).replace("${saldo}", formatCurrency(Saldo)).replace("${empresa}", empresa);
        }
         
        return encodeURIComponent(mensaje);
    }

    useEffect(() => {
        setLoading(true)
        GetData('clientes/listadocartera').then((result) => {
                if (result.error === false){
                    const clientes = result.clientes
                    let sumacartera = 0;
                    setListaClientes(clientes)
                    clientes.forEach((cliente) => {
                        sumacartera += parseFloat(cliente.saldo_cliente);
                    });                      

                    setTotalCartera(formatCurrency(sumacartera));
                }
                setLoading(false)
        })
      
    }, [Refrest])

    

    const EliminarEspacios = (telefono) => {
          return telefono.replace(" ", "");  
    }

  return (
    <>
         <section className='section dashboard'>
            <div className="card">
            <div className='card-header'>Cartera de Clientes</div>    
            <div className="card-body pb-0">

                    <div className='row'>                          
                                                    
                            <div className='col-md-2 mt-4'>
                                <span className='label'> N° Clientes:</span> <span className='texto'>{ListaClientes.length}</span>                                  
                            </div>
                            <div className='col-md-2  mt-4'>
                                <span className='label'>Saldo: </span> <span className='texto'>{TotalCartera}</span>
                            </div>
                    </div>    

                    {Loading && 
                    <div class="progress mt-3">
                        <div class="progress-bar progress-bar-striped bg-info progress-bar-animated" role="progressbar" style={{width : '100%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>      
                    }      
                    <div className="table-responsive">
                        <table class="table table-striped  mt-2">
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Nombre del Cliente</th>
                                <th scope="col">Saldo</th>
                               {/*  <th scope="col">Op</th> */}
                            </tr>
                            </thead>
                            <tbody>
                            {ListaClientes.map((cli, index) => 
                            <tr index={cli.id_cartera_cliente}>
                                <th scope="row">{index+1}</th>
                                <td>{mayusculaIniciales(cli.nombre_cli)}</td>
                                <td >{formatCurrency(cli.saldo_cliente)}</td>
                                {/* <td align='center'> 
                                    {cli.telefono_cli && 
                                        <a target='_blank' href={`https://web.whatsapp.com/send?phone=57${EliminarEspacios(cli.telefono_cli)}&text=${Mensaje(cli.nombre_cli, cli.saldo_cliente)}`} title='Escribir a Whatsapp'>
                                            <img src='/assets/img/whatsapp.png' style={{marginLeft:'4px', width: '24px', float:'none'}} />
                                        </a>
                                    }
                               </td> */}
                            </tr>
                            )}    
                            
                            </tbody>
                        </table>
                    </div>
                    </div>
                </div>
            </section>    
    
    </>
  )
}

export default PageCarteraClientes