import { getFromStorage } from '../components/utils';
import { URL_SERVICIOS } from './UrlWebServis';
import { getToken, getID } from './Usuario';

export function PostData(type, userData){    
    const token = getToken() ? getToken() : '000';
    const id = getID() ? getID() : 0;
    const url_api = URL_SERVICIOS;
    const campus = getFromStorage("contarsEmpresa");
    
    console.log("empresa", campus)
    return new Promise((resolve, reject) => {
        fetch(url_api+type+'/'+id+'/'+token+'/'+campus,{
            method: 'POST',
            body: JSON.stringify(userData)
        })
        .then((response) => response.json())
        .then((responseJson) => {
            resolve(responseJson);
        })
        .catch((error) => {
            console.log("Error " + error);
           userData.error_login =  true;
           reject(error);
           
        })
    })    
}