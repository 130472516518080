import { getFromStorage } from "../components/utils";


export function getToken() {
  const usuario = getFromStorage('userContaRs');
  return usuario ? usuario.token_us : '';
}

export function getID() {
  const usuario = getFromStorage('userContaRs');
  return usuario ? usuario.id_usuario : '';
}

export function getSede() {
  const empresa = getFromStorage('contarsEmpresa');
  return empresa || '';
}

export function Usuario() {
  const usuario = getFromStorage('userContaRs');
  return usuario || '';
}
