import React, { useEffect, useState } from 'react'
import { GetData } from '../../services/GetData'
import Swal from 'sweetalert2'
import { PostData } from '../../services/PostData';
import { formatCurrency } from '../../components/utils';
import BotonTabla from '../../components/BotonTabla';

const PageProductos = () => {
    const [Loading, setLoading] = useState(false)
    const [Refrest, setRefrest] = useState(false)
    const [ListadoProductos, setListadoProductos] = useState([])
    const [ListadoProveedor, setListadoProveedor] = useState([])
    const [ListadoCategorias, setListadoCategorias] = useState([])
    const [ListaFiltrada, setListaFiltrada] = useState([])
    const [FiltrarPor, setFiltrarPor] = useState(1)
    const [Filtrar, setFiltrar] = useState('')

    //producto
    const [IdProducto, setIdProducto] = useState(0)
    const [CodigoPro, setCodigoPro] = useState(0)
    const [Proveedor, setProveedor] = useState(0)
    const [Categoria, setCategoria] = useState(0)
    const [NombreProducto, setNombreProducto] = useState("")
    const [codigopersPro, setCodigopersPro] = useState('');
    const [nombreCortoPro, setNombreCortoPro] = useState('');
    const [existencia, setExistencia] = useState(0);
    const [existenciaMin, setExistenciaMin] = useState(0);
    const [precioCosto, setPrecioCosto] = useState(0.0);
    const [precioVenta1, setPrecioVenta1] = useState(0.0);
    const [precioVenta2, setPrecioVenta2] = useState(0.0);
    const [precioVenta3, setPrecioVenta3] = useState(0.0);
    const [precioVenta4, setPrecioVenta4] = useState(0.0);
    const [precioVentaMin, setPrecioVentaMin] = useState(0.0);
    const [servicioPro, setServicioPro] = useState(false);
    const [sinDescuento, setSinDescuento] = useState(false);

    useEffect(() => {
        GetData('productos').then((result) => {
                if (result.error === false){
                    setListadoProductos(result.productos)
                    setListaFiltrada(result.productos);
                }
        })
        GetData('proveedor/listado').then((result) => {
                if (result.error === false){
                    setListadoProveedor(result.proveedores)
                }
        })
        GetData('categorias/listado').then((result) => {
                if (result.error === false){
                  setListadoCategorias(result.categorias)
                }
        })
    }, [Refrest])
    

    const Modificar = (Prod) => {
          setIdProducto(Prod.id_producto)
          setCodigoPro(Prod.codigo_pro)
          setCodigopersPro(Prod.codigopers_pro)
          setNombreProducto(Prod.nombre_pro)
          setNombreCortoPro(Prod.nombrecorto_pro)
          setExistencia(Prod.existencia)
          setExistenciaMin(Prod.existencia_min)
          setPrecioCosto(Prod.precio_costo)
          setPrecioVenta1(Prod.precio_venta1)
          setPrecioVenta2(Prod.precio_venta2)
          setPrecioVenta3(Prod.precio_venta3)
          setPrecioVenta4(Prod.precio_venta4)
          setCategoria(Prod.id_categoria)
          setProveedor(Prod.id_proveedor)
          setPrecioVentaMin(Prod.precio_venta_min)
          setServicioPro(Prod.servicio_pro === 'S' ? true: false)
          setSinDescuento(Prod.sindescuento === 'S' ? true: false)

      const modal = document.getElementById('modalProducto');

      // Verificar si el modal existe antes de intentar abrirlo
      if (modal) {
        // Usar el método modal() para abrir el modal
        const modalInstancia = new window.bootstrap.Modal(modal);
        modalInstancia.show();
      }
}  

    const NuevoPro = () => {
        
        const modal = document.getElementById('modalProducto');

        // Verificar si el modal existe antes de intentar abrirlo
        if (modal) {
        // Usar el método modal() para abrir el modal
        const modalInstancia = new window.bootstrap.Modal(modal);
        modalInstancia.show();
        }
    }

    const Eliminar = (id_producto, nombre) => {

      Swal.fire({
          title: '¿Estás seguro?',
          text: '¡No podrás revertir esto! ¿Estas seguro de eliminar a: ' + nombre + '?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sí, eliminarlo'
      }).then((result) => {
          if (result.isConfirmed) {
             

              GetData('productos/eliminar/' + id_producto, null).then ((result) =>{

                  let responseJSON = result;
      
                  if (responseJSON.error === false){
                      Swal.fire({
                          title: 'Eliminado',
                          text: 'El producto ha sido eliminado correctamente',
                          icon: 'success'
                      });
                      setRefrest(!Refrest);
                  }
              })
              // Muestra un mensaje de éxito después de eliminar
            
          }
      });
      
  }

    const limpiarFormulario = () => {
        setNombreProducto("")
        setCodigopersPro("")
        setNombreCortoPro("")
        setExistencia(0)
        setExistenciaMin(0)
        setPrecioCosto(0)
        setPrecioVenta1(0.0)
        setPrecioVenta2(0.0)
        setPrecioVenta3(0.0)
        setPrecioVenta4(0.0)
        setPrecioVentaMin(0.0)
        setServicioPro(false)
        setCategoria(0)
        setProveedor(0)
        setSinDescuento(false)
    }

    const validarFormulario = () => {
        if (
          codigopersPro.trim() === '' ||
          NombreProducto.trim() === '' ||
          nombreCortoPro.trim() === '' ||
          existencia <= 0 ||
          existenciaMin <= 0 ||
          precioCosto <= 0 ||
          precioVenta1 <= 0 ||
          precioVentaMin <= 0
        ) {
          alert('Por favor, complete todos los campos requeridos.');
          return false;
        }
        return true;
      };
    
      const guardarProducto = async () => {
        if (!validarFormulario()) {
          return;
        }
    
        const producto = {
          id_producto: IdProducto,
          codigo_pro: CodigoPro,
          codigopers_pro: codigopersPro,
          nombre_pro: NombreProducto,
          nombrecorto_pro: nombreCortoPro,
          existencia: existencia,
          existencia_min: existenciaMin,
          precio_costo: precioCosto,
          precio_venta1: precioVenta1,
          precio_venta2: precioVenta2,
          precio_venta3: precioVenta3,
          precio_venta4: precioVenta4,
          precio_venta_min: precioVentaMin,
          id_categoria: Categoria,
          id_proveedor: Proveedor,
          servicio_pro: servicioPro ? 'S' : 'N',
          sindescuento: sinDescuento ? 'S' : 'N'
        };
    
        try {
          //const response = await axios.post('URL_DE_TU_API/guardar_post', producto);
          const response = await PostData('productos/guardar', producto);
          if (!response.error) {
            Swal.fire({
                title: 'Cargar Guardado',
                text: 'Producto guardado correctamente.',
                icon:"success",
                confirmButtonText: 'Aceptar'
              });                  
              limpiarFormulario()
              setRefrest(!Refrest)
            // Aquí puedes agregar cualquier otra acción como cerrar el modal, recargar la lista, etc.
          } else {
            Swal.fire({
                title: 'Error al Guardar',
                text: response.mensaje,
                icon: 'error',
                confirmButtonText: 'Aceptar'
              });
          }
        } catch (error) {
            Swal.fire({
                title: 'Error al Guardar',
                text: "Error al guardar el producto: " + error,
                icon: 'error',
                confirmButtonText: 'Aceptar'
              });
          console.error('Error al guardar el producto:', error);
          
        }
      };


      useEffect(() => {
        let listaFiltrada = ListadoProductos;
      
        if (Filtrar) {
          listaFiltrada = listaFiltrada.filter(
            (alumno) =>
              alumno.nombre_pro && alumno.nombre_pro.toLowerCase().includes(Filtrar.toLowerCase())
          );
        }
      
        setListaFiltrada(listaFiltrada);
      
      
      }, [Filtrar])


  return (
    <>
     <section className='section dashboard'>
            <div class="card">
                  
                <div className="card-body pb-0">
                    <h5 className="card-title">Inventario de Productos</h5>

                    <div className='row'>                          
                            <div className='col-md-4'>  
                               <span className='label'>Filtrar</span> 
                                <input type="text" style={{ backgroundColor: '#eaf2ff', color: '#004085', fontWeight: 'bold' }} 
                                  className="form-control form-control-sm" id="fnombres" name="fnombres" value={Filtrar} onChange={(e) => setFiltrar(e.target.value)} />                                  
                            </div>
                            <div className='col-md-2'>
                                <button type="button" className="btn btn-primary btn-sm mt-4 ml-2" onClick={NuevoPro} >
                                    <i className="bi bi-person-plus"></i> Nuevo Producto
                                </button>
                            </div>
                            <div  className='col-md-2 mt-4'>
                                    <span className='label'> N° Productos:</span> <span className='texto'>{ListadoProductos.length}</span> 
                            </div>
                    </div>    

                    {Loading && 
                    <div class="progress mt-3">
                        <div class="progress-bar progress-bar-striped bg-info progress-bar-animated" role="progressbar" style={{width : '100%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>      
                    }      
                    <div className="table-responsive">
                      <table class="table table-striped  mt-2">
                          <thead>
                          <tr>
                              <th scope="col">#</th>
                              <th scope="col">Nombre del Producto</th>
                              <th scope="col">Exist.</th>
                              <th scope="col">P. Costo</th>
                              <th scope="col">P. Venta 1</th>
                              <th scope="col">P. Venta 2</th>
                              <th scope="col">P. Venta 3</th>
                              <th scope="col">P. Mínimo</th>
                              <th scope="col">Categoría</th>
                              <th scope="col">Proveedor</th>
                              <th scope="col">Op</th>
                          </tr>
                          </thead>
                          <tbody>
                          {ListaFiltrada.map((pro, index) => 
                          <tr index={pro.id_producto}>
                              <th scope="row">{index+1}</th>
                              <td> <a href='#' onClick={()=> Modificar(pro)} title='Ver Producto' >{pro.nombre_pro}</a></td>
                              <td align='center'>{pro.existencia}</td>
                              <td>{formatCurrency(pro.precio_costo)}</td>
                              <td>{formatCurrency(pro.precio_venta1)}</td>
                              <td>{formatCurrency(pro.precio_venta2)}</td>
                              <td>{formatCurrency(pro.precio_venta3)}</td>
                              <td>{formatCurrency(pro.precio_venta_min)}</td>
                              <td></td>
                              <td></td>
                              <td>
                                <BotonTabla icono='lapiz.png' titulo='Modificar' evento={()=>Modificar(pro)} />
                                <BotonTabla icono='borrar.png' titulo='Eliminar' evento={()=>Eliminar(pro.id_producto, pro.nombre_pro)} />
                              </td>
                          </tr>
                          )}    
                          
                          </tbody>
                      </table>
                    </div>
                    </div>
                </div>
            </section>    

            <div className="modal fade" id="modalProducto" tabIndex="-1" data-bs-backdrop="static">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              <i className="bi bi-pencil-square"></i> {IdProducto === 0 ? <>Nuevo Producto</> : <>Modificar Producto N° {IdProducto}</>}
            </h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className='row'>
              <div className='col-md-3'>
                <span className='label'>Código Personalizado</span>
                <input type="text" className="form-control form-control-sm estilocampo" id="codigopers_pro" name="codigopers_pro" value={CodigoPro} onChange={(e) => setCodigoPro(e.target.value)} />
              </div>
              <div className='col-md-6'>
                <span className='label'>Nombre Producto</span>
                <input type="text" className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={NombreProducto} onChange={(e) => setNombreProducto(e.target.value)} />
              </div>
            </div>
            <div className='row'>
              
              <div className='col-md-3'>
                <span className='label'>Nombre Corto</span>
                <input type="text" className="form-control form-control-sm estilocampo" id="nombrecorto_pro" name="nombrecorto_pro" value={nombreCortoPro} onChange={(e) => setNombreCortoPro(e.target.value)} />
              </div>
              <div className='col-md-3'>
                <span className='label'>Existencia</span>
                <input type="number" className="form-control form-control-sm estilocampo" id="existencia" name="existencia" value={existencia} onChange={(e) => setExistencia(e.target.value)} />
              </div>
              <div className='col-md-3'>
                <span className='label'>Existencia Mínima</span>
                <input type="number" className="form-control form-control-sm estilocampo" id="existencia_min" name="existencia_min" value={existenciaMin} onChange={(e) => setExistenciaMin(e.target.value)} />
              </div>
            </div>
            <div className='row'>
              <div className='col-md-3'>
                <span className='label'>Precio Costo</span>
                <input type="number" className="form-control form-control-sm estilocampo" id="precio_costo" name="precio_costo" value={precioCosto} onChange={(e) => setPrecioCosto(e.target.value)} />
              </div>
              <div className='col-md-3'>
                <span className='label'>Precio Venta 1</span>
                <input type="number" className="form-control form-control-sm estilocampo" id="precio_venta1" name="precio_venta1" value={precioVenta1} onChange={(e) => setPrecioVenta1(e.target.value)} />
              </div>
              <div className='col-md-3'>
                <span className='label'>Precio Venta 2</span>
                <input type="number" className="form-control form-control-sm estilocampo" id="precio_venta2" name="precio_venta2" value={precioVenta2} onChange={(e) => setPrecioVenta2(e.target.value)} />
              </div>
              <div className='col-md-3'>
                <span className='label'>Precio Venta 3</span>
                <input type="number" className="form-control form-control-sm estilocampo" id="precio_venta3" name="precio_venta3" value={precioVenta3} onChange={(e) => setPrecioVenta3(e.target.value)} />
              </div>
            </div>
            <div className='row'>
              <div className='col-md-3'>
                <span className='label'>Precio Venta 4</span>
                <input type="number" className="form-control form-control-sm estilocampo" id="precio_venta4" name="precio_venta4" value={precioVenta4} onChange={(e) => setPrecioVenta4(e.target.value)} />
              </div>
              <div className='col-md-3'>
                <span className='label'>Precio Venta Mínimo</span>
                <input type="number" className="form-control form-control-sm estilocampo" id="precio_venta_min" name="precio_venta_min" value={precioVentaMin} onChange={(e) => setPrecioVentaMin(e.target.value)} />
              </div>
              <div className='col-md-3'>
                <span className='label'>Es Servicio</span>
                <input type="checkbox" className="form-check-input" id="servicio_pro" name="servicio_pro" checked={servicioPro} onChange={(e) => setServicioPro(e.target.checked)} />
              </div>
              <div className='col-md-3'>
                <span className='label'>Sin Descuento</span>
                <input type="checkbox" className="form-check-input" id="sindescuento" name="sindescuento" checked={sinDescuento} onChange={(e) => setSinDescuento(e.target.checked)} />
              </div>
              <div className='col-md-6'>
                <span className='label'>Proveedor</span>
                                <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={Proveedor} onChange={(e) => setProveedor(e.target.value)} >
                                <option  value={null}>Seleccionar</option>
                                {ListadoProveedor.map((prov, index) => {
                                            return <option key={index} value={prov.id_proveedor}>{prov.nombre_prov}</option>
                                    })}
                                </select> 

              </div>
              <div className='col-md-6'>
                <span className='label'>Categoría</span>
                <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={Categoria} onChange={(e) => setCategoria(e.target.value)} >
                                <option  value={null}>Seleccionar</option>
                                {ListadoCategorias.map((cat, index) => {
                                            return <option key={index} value={cat.id_categoria}>{cat.nombre_categoria}</option>
                                    })}
                                </select> 
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i className="bi bi-door-closed"></i> Cerrar</button>
            <button type="button" className="btn btn-primary btn-sm" data-bs-toggle="tooltip" data-bs-placement="bottom" onClick={guardarProducto} data-bs-original-title="Guardar">
              <i className="bi bi-clipboard-plus"></i> Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default PageProductos