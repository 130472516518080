import React, { useContext, useEffect, useState } from 'react'
import { GetData } from '../services/GetData'
import { Form, Button } from 'react-bootstrap';
import { Usuario } from '../services/Usuario';
import { PostData } from '../services/PostData';
import Swal from 'sweetalert2'

import config from '../config';
import { SessionContext } from '../SessionContext';

const PerfilUsuario = () => {
    const usuario = Usuario();
    const [PerfilPersona, setPerfilPersona] = useState([])    
    const [fotoPerfil, setfotoPerfil] = useState("")    
    const [Email, setEmail] = useState("")    
    const [Nombres, setNombres] = useState("")    
    const [Telefono, setTelefono] = useState("")    
    const [Nick, setNitk] = useState("")    
    const [Password, setPassword] = useState("")    
    const [NewPassoword, setNewPassword] = useState("")    
    const [RePassword, setRePassword] = useState("")    
    const { FotoPerfil } = useContext(SessionContext);

    const [image, setImage] = useState(null);
    const [preview, setPreview] = useState('');

    const handleCapture = (e) => {
      const file = e.target.files[0];
      setImage(file);
      setPreview(URL.createObjectURL(file));
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      // Aquí puedes enviar la imagen al servidor o procesarla como desees
    };

    const handleResize = () => {
      // Aquí puedes implementar la lógica para redimensionar la imagen
      // Puedes usar una librería como pica o una función personalizada
    };


    useEffect(() => {        
        GetData('usuarios/perfilusuario', null).then ((result) =>{
            let responseJSON = result;

            if (responseJSON.error === false){
                setPerfilPersona(result.datos)
                setEmail(result.datos.email_us)
                setNombres(result.datos.nombre_usuario)
                setTelefono(result.datos.celular_us)
                setNitk(result.datos.usuario_us)
               
            }
        })
    }, [])
        


   

    const GuardarPerfil = () => {
        const datos = {
          email_us: Email,
          nombre_usuario: Nombres,
          celular_us:Telefono,
          usuario_us: Nick
        }
        PostData('usuarios/saveperfiladmin', datos).then((result) => {
              if (result.error === false){
                Swal.fire({
                  title: 'Perfil Guardados',
                  text: result.mensaje,
                  icon:"success",
                  confirmButtonText: 'Aceptar'
                });  
              }else{
                Swal.fire({
                  title: 'Error al Guardar',
                  text: result.mensaje,
                  icon:'error',
                  confirmButtonText: 'Aceptar'
                });  
              }
        })
    }

    const CambiarPassword = () => {
        
        const datos = {
          password: Password,
          new_password: NewPassoword,
        }
        PostData('usuarios/cambiarpassword', datos).then((result) => {
              if (result.error === false){
                Swal.fire({
                  title: 'Perfil Guardados',
                  text: result.mensaje,
                  icon:"success",
                  confirmButtonText: 'Aceptar'
                });  
                setNewPassword('')
                setRePassword('')
              }else{
                Swal.fire({
                  title: 'Error al Guardar',
                  text: result.mensaje,
                  icon:'error',
                  confirmButtonText: 'Aceptar'
                });  
              }
        })
    }

    const abriTomarFoto = () => {
      const modal = document.getElementById('modalFoto');

      // Verificar si el modal existe antes de intentar abrirlo
      if (modal) {
        // Usar el método modal() para abrir el modal
        const modalInstancia = new window.bootstrap.Modal(modal);
        modalInstancia.show();
      }
    }

  return (
    <>
     <div className="card">
      <div className='card-body'>
         <section className="section profile">

              <div className='met-profile mt-3'>
                <div className='row'>
                     <div className="col-lg-4 align-self-center mb-3 mb-lg-0">
                            <div className="met-profile-main">
                                <div className="met-profile-main-pic">
                                    <img src={config.API_URL_FOTOS + FotoPerfil} alt="" width="110" height="110" className="rounded-circle" />
                                    <span className="met-profile_main-pic-change" onClick={()=> abriTomarFoto()}>
                                        <i className="bi bi-camera-fill"></i>
                                    </span>
                                </div>
                                <div className="met-profile_user-detail">
                                    <h5 className="met-user-name">{usuario.nombre_usuario}</h5>                                                        
                                    <p className="mb-0 met-user-name-post">{usuario.DescripcionTipoUs}</p>                                                        
                                </div>
                            </div>                                                
                      </div>
                </div>
                <div className='row'>

                <div class="row mt-3">
                                            <div class="col-lg-6 col-xl-6">
                                                <div class="card">
                                                    <div class="card-header">
                                                        <div class="row align-items-center">
                                                            <div class="col">                      
                                                                Información Personal
                                                            </div>                                                     
                                                        </div>                                    
                                                    </div>
                                                    <div class="card-body">                       
                                                        <div class="form-group mb-3 row mt-3">
                                                            <label class="col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center form-label">Nombres completos</label>
                                                            <div class="col-lg-9 col-xl-8">
                                                                <input class="form-control form-control-sm" type="text" value={Nombres} onChange={(e)=> setNombres(e.target.value)}  />
                                                            </div>
                                                        </div>
                                                        <div class="form-group mb-3 row mt-3">
                                                            <label class="col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center form-label">Nick</label>
                                                            <div class="col-lg-9 col-xl-8">
                                                                <input class="form-control form-control-sm" type="text" value={Nick} onChange={(e)=> setNitk(e.target.value)}  />
                                                            </div>
                                                        </div>
                                                        <div class="form-group mb-3 row">
                                                            <label class="col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center form-label">Célular</label>
                                                            <div class="col-lg-9 col-xl-8">
                                                                <div class="input-group">
                                                                    <span class="input-group-text"><i class="las la-phone"></i></span>
                                                                    <input type="text" class="form-control form-control-sm" value={Telefono} onChange={(e)=> setTelefono(e.target.value)}  placeholder="Phone" aria-describedby="basic-addon1" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group mb-3 row">
                                                            <label class="col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center form-label">Correo Electrónico</label>
                                                            <div class="col-lg-9 col-xl-8">
                                                                <div class="input-group">
                                                                    <span class="input-group-text"><i class="las la-at"></i></span>
                                                                    <input type="text" class="form-control form-control-sm" value={Email} onChange={(e)=> setEmail(e.target.value)} placeholder="Email" aria-describedby="basic-addon1" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group mb-3 row">
                                                            <label class="col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center form-label">Tipo Usuario</label>
                                                            <div class="col-lg-9 col-xl-8">
                                                                <input class="form-control form-control-sm" type="text" value={PerfilPersona.DescripcionTipoUs} disabled />
                                                            </div>
                                                        </div>
                            
                                                        
                                                       
                                                       
                                                        <div class="form-group mb-3 row">
                                                            <label class="col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center form-label">Estado</label>
                                                            <div class="col-lg-9 col-xl-8">
                                                            <input
                                                                  className="form-control form-control-sm"
                                                                  type="text"
                                                                  value={PerfilPersona.estado_us === "1" ? 'Activo' : 'Inactivo'}
                                                                  disabled
                                                                />
                                                            </div>
                                                        </div>
                                                        <div class="form-group mb-3 row">
                                                            <div class="col-lg-9 col-xl-8 offset-lg-3">
                                                                <button type="submit" class="btn btn-primary" onClick={()=> GuardarPerfil()} disabled={!Nombres || !Email || !Nick || !Telefono}>Guardar</button>
                                                                <button type="button" class="btn btn-danger ml-2">Cancelar</button>
                                                            </div>
                                                        </div>                                                    
                                                    </div>                                            
                                                </div>
                                            </div>  
                                            <div class="col-lg-6 col-xl-6">
                                                <div class="card">
                                                    <div class="card-header">
                                                        Cambiar Contraseña
                                                    </div>
                                                    <div class="card-body"> 
                                                        <div class="form-group mb-3 row mt-3">
                                                            <label class="col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center form-label">Contraseña actual</label>
                                                            <div class="col-lg-9 col-xl-8">
                                                                <input class="form-control form-control-sm " value={Password} onChange={(e)=> setPassword(e.target.value)} type="password" placeholder="Password" />
                                                            </div>
                                                        </div>
                                                        <div class="form-group mb-3 row">
                                                            <label class="col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center form-label">Nueva contraseña</label>
                                                            <div class="col-lg-9 col-xl-8">
                                                                <input class="form-control form-control-sm " value={NewPassoword} onChange={(e)=> setNewPassword(e.target.value)} type="password" autocomplete="off" placeholder="Nueva Contraseña" />
                                                            </div>
                                                        </div>
                                                        <div class="form-group mb-3 row">
                                                            <label class="col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center form-label">Confirmar Contraseña</label>
                                                            <div class="col-lg-9 col-xl-8">
                                                                <input class="form-control form-control-sm"  value={RePassword} onChange={(e)=> setRePassword(e.target.value)} type="password" autocomplete="off" placeholder="Confirmar contraseña" />
                                                            </div>
                                                        </div>
                                                        <div class="form-group mb-3 row">
                                                            <div class="col-lg-9 col-xl-8 offset-lg-3">
                                                                <button type="submit" class="btn btn-primary" onClick={()=>CambiarPassword()} disabled={!Password || !NewPassoword || !RePassword || (NewPassoword !== RePassword) }>Cambiar Contraseña</button> 
                                                                <button type="button" class="btn btn-danger ml-2">Cancelar</button>
                                                            </div>
                                                        </div>   
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header">                                                    
                                                        Other Settings
                                                    </div>
                                                    <div class="card-body"> 
    
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" value="" id="Email_Notifications" checked="" />
                                                            <label class="form-check-label" for="Email_Notifications">
                                                                Email Notifications
                                                            </label>
                                                            <span class="form-text text-muted font-12 mt-0">Do you need them?</span>
                                                          </div>
                                                          <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" value="" id="API_Access" />
                                                            <label class="form-check-label" for="API_Access">
                                                                API Access
                                                            </label>
                                                            <span class="form-text text-muted font-12 mt-0">Enable/Disable access</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>


                </div>
              </div>
          </section>
    </div>
    </div>


    <div className="modal fade" id="modalFoto" tabindex="-1" data-bs-backdrop="static">
        <div className="modal-dialog modal-lg">
              <div className="modal-content">
        
                    <div className="modal-header">
                      <h5 className="modal-title"><i className="bi bi-pencil-square"></i> </h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        
                    </div>
              </div>  
        </div>
      </div>
    </>
  )
}

export default PerfilUsuario