import React, { useContext, useEffect, useState } from 'react'
import { PostData } from '../../services/PostData'
import Swal from 'sweetalert2';
import { SessionContext } from '../../SessionContext';
import config from '../../config';
import 'animate.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import { signInWithPopup } from "firebase/auth";
import { auth, googleProvider, facebookProvider } from '../../firebase';


const LoginPage = () => {

  const [Email, setEmail] = useState("")
  const [Password, setPassword] = useState("")
  const [Sede, setSede] = useState(null)
  const { setUser } = useContext(SessionContext);
  

  useEffect(() => {
      localStorage.setItem('URL_SERVICIOS', config.API_URL_PPAL);   
  }, [])
 
  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const { user } = result;

      const datos = {
        type: 'g',
        email: user.email,
        password: '',
        name: user.displayName,
        provider: user.providerId,
        uid: user.uid,
        avatar: user.photoURL
      };

      PostData('login/loginsuccess', datos).then ((result) =>{
            setUser(result.usuario); 
      })


    } catch (error) {
      console.error('Google Sign-In Error:', error);
    }
  };

  const handleFacebookSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, facebookProvider);
      
      console.log('Facebook Sign-In Successful ', result);
    } catch (error) {
      console.error('Facebook Sign-In Error:', error);
    }
  };
  

  const login = (e) => {
      e.preventDefault();

      const datos = {
            email: Email,
            password: Password
      }

        PostData('login', datos).then ((result) =>{
          let responseJSON = result;
          if (responseJSON.error === false){               
               setUser(result.usuario); 
               //navigate("/h");
           }else{
            Swal.fire({
              title: 'Usuario no válidos',
              text: responseJSON.mensaje,
              icon: 'error',
              confirmButtonText: 'Aceptar'
            });
            
        }
      })

  }

  return (
  <main className='fondologin'>
        <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

              {/* <div className="d-flex justify-content-center py-4">
                <a href="index.html" className="logo d-flex align-items-center w-auto">
                  <img src="assets/img/LogoCnotas.png" alt="" />
                  <span className="d-none d-lg-block">{config.NOMBRE_APP} {config.VERSION_APP}</span>
                </a>
              </div> */}

              <div className="card mb-3 animate__animated animate__pulse">

              <div className="card-body p-0 auth-header-box">
                                    <div className="text-center p-3">
                                        <a href="index.html" className="logo logo-admin">
                                            <img src="assets/img/logo-sm.png" height="50" alt="logo" className="auth-logo" />
                                        </a>
                                        <h4 className="mt-3 mb-1 fw-semibold text-white font-18">{config.NOMBRE_APP} {config.VERSION_APP}</h4>   
                                        <p className="text-muted  mb-0">Inicie sesión para continuar</p>  
                                    </div>
                                </div>
                <div className="card-body login">

{/*                   <div className="pt-2 pb-2">
                     <h5 className="card-title text-center pb-0 fs-4">Ingresa a tu cuenta</h5> 
                    <span className="text-center small">Inicie sesión para continuar</span>
                  </div>
 */}
                  <form className="row g-3 needs-validation"  onSubmit={login}>
                    <div className="col-12 mt-4">
                      <label htmlFor="yourUsername" className="form-label label">Correo Electrónico</label>
                      <div className="input-group has-validation">
                        {/* <span className="input-group-text" id="inputGroupPrepend">@</span> */}
                        <input type="text" name="Email" 
                            className="form-control form-control-sm" style={{  color: '#004085', fontWeight: 'bold' }} id="yourUsername" required  
                            onChange={e => setEmail(e.target.value)} />
                        <div className="invalid-feedback">Please enter your username.</div>
                      </div>
                    </div>

                    <div className="col-12">
                      <label htmlFor="yourPassword" className="form-label label">Contraseña</label>
                      <input type="password" name="Password" className="form-control form-control-sm" style={{  color: '#004085', fontWeight: 'bold' }} id="yourPassword" required  onChange={e => setPassword(e.target.value)} /> 
                      <div className="invalid-feedback">Please enter your password!</div>
                    </div>

                    <div className="form-group row mt-3">
                                            <div className="col-sm-6">
                                                <div className="form-check form-switch form-switch-success">
                                                    <input className="form-check-input" type="checkbox" id="customSwitchSuccess" />
                                                    <label className="form-check-label" for="customSwitchSuccess" style={{fontSize: '.8125rem'}}>Recordarme</label>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 text-end">
                                                <a href="auth-recover-pw.html" className="text-muted font-13"><i className="dripicons-lock"></i> ¿Olvidaste tu contraseña?</a>                                    
                                            </div>
                                        </div>
                   
                    <div className="col-12">
                      <button className="btn btn-primary w-100" type="submit" disabled={!Email || !Password}>Ingresar <i className="bi bi-box-arrow-in-right"></i></button>
                    </div>
                     <div className="col-12 text-center">
                      <p className="small mb-0">¿No tienes una cuenta? <Link to="/register">Crea una cuenta</Link></p>
                    </div> 
                    

                  </form>
                  <div class="text-center mt-n5">
                                        <h6 class="card-bg px-3 my-4 d-inline-block">O Iniciar sesión con</h6>
                                    </div>
                                    <div class="d-flex justify-content-center mb-1">
                                       {/*  <a href="#"  onClick={handleFacebookSignIn} class="d-flex justify-content-center align-items-center thumb-sm bg-soft-primary rounded-circle me-2">
                                              <FontAwesomeIcon icon={faFacebook} className="align-self-center" />
                                        </a> */}
                                        <a href="#" onClick={handleGoogleSignIn} class="d-flex justify-content-center align-items-center thumb-sm bg-soft-danger rounded-circle">
                                            <FontAwesomeIcon icon={faGoogle} className="align-self-center" />
                                            
                                        </a>
                                    </div>
                </div>
              </div>

              <div className="credits">
                    Designed by <a href={config.URL_EMPRESA}>Innovación Digital</a>
              </div>

            </div>
          </div>
        </div>

      </section>

    </div>
  </main>
  )
}

export default LoginPage