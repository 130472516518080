import React, { useEffect, useState } from 'react'
import { GetData } from '../services/GetData'
import { PostData } from '../services/PostData'
import Swal from 'sweetalert2'
import BotonTabla from '../components/BotonTabla'

const PageCategorias = () => {
    const [FiltrarPor, setFiltrarPor] = useState(1)
    const [FiltrarPorText, setFiltrarPorText] = useState('Nombre')
    const [ListaProveedores, setListaProveedores] = useState([])
    const [Loading, setLoading] = useState(false)
    const [Refrest, setRefrest] = useState(false)


    const [IdCategoria, setIdCategoria] = useState(0);
    const [NombreCate, setNombreCate] = useState('');
  

    useEffect(() => {
        setLoading(true)
        GetData('categorias').then((result) => {
                if (result.error === false){
                    setListaProveedores(result.categorias)
                }
                setLoading(false)
        })
      
    }, [Refrest])

  const Modificar = (Cat) => {
        setIdCategoria(Cat.id_categoria)
        setNombreCate(Cat.nombre_categoria)

        const modal = document.getElementById('modalCategoria');

        // Verificar si el modal existe antes de intentar abrirlo
        if (modal) {
          // Usar el método modal() para abrir el modal
          const modalInstancia = new window.bootstrap.Modal(modal);
          modalInstancia.show();
        }
  }  

  const NuevaCategoria = () => {
        setIdCategoria(0)
        setNombreCate("")
        const modal = document.getElementById('modalCategoria');

        // Verificar si el modal existe antes de intentar abrirlo
        if (modal) {
        // Usar el método modal() para abrir el modal
        const modalInstancia = new window.bootstrap.Modal(modal);
        modalInstancia.show();
        }
    }

    const Eliminar = (id_proveedor, nombre) => {

      Swal.fire({
          title: '¿Estás seguro?',
          text: '¡No podrás revertir esto! ¿Estas seguro de eliminar a: ' + nombre + '?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sí, eliminarlo'
      }).then((result) => {
          if (result.isConfirmed) {
             

              GetData('categorias/eliminar/' + id_proveedor, null).then ((result) =>{

                  let responseJSON = result;
      
                  if (responseJSON.error === false){
                      Swal.fire({
                          title: 'Eliminado',
                          text: 'La categoria ha sido eliminado correctamente',
                          icon: 'success'
                      });
                      setRefrest(!Refrest);
                  }
              })
              // Muestra un mensaje de éxito después de eliminar
            
          }
      });
      
  }

    const validarFormulario = () => {
        if (
            NombreCate.trim() === '' 
       
        ) {
          alert('Por favor, complete todos los campos requeridos.');
          return false;
        }
        return true;
      };

      const limpiarFormulario = () => {
        setIdCategoria(0)
        setNombreCate("")
    }

   const GuardarCategoria = async () => {
            if (!validarFormulario()) {
                return;
            }
            
            // Construcción del objeto proveedor
            const datos = {
                id_categoria: IdCategoria,
                nombre_categoria: NombreCate,
            };

            try {
                //const response = await axios.post('URL_DE_TU_API/guardar_post', producto);
                const response = await PostData('categorias/guardar', datos);
                if (!response.error) {
                  Swal.fire({
                      title: 'Guardar',
                      text: 'Categoria guardada correctamente.',
                      icon:"success",
                      confirmButtonText: 'Aceptar'
                    });                  
                    limpiarFormulario()
                    setRefrest(!Refrest)
                  // Aquí puedes agregar cualquier otra acción como cerrar el modal, recargar la lista, etc.
                } else {
                  Swal.fire({
                      title: 'Error al Guardar',
                      text: response.mensaje,
                      icon: 'error',
                      confirmButtonText: 'Aceptar'
                    });
                }
              } catch (error) {
                  Swal.fire({
                      title: 'Error al Guardar',
                      text: "Error al guardar la categoria: " + error,
                      icon: 'error',
                      confirmButtonText: 'Aceptar'
                    });
                console.error('Error al guardar la categoria:', error);
                
              }
   } 


  const eventFiltro = (texto, codigo) => {
    setFiltrarPor(codigo)
    setFiltrarPorText(texto)
    }


  return (
    <>
         <section className='section dashboard'>
            <div class="card">
                  
                <div className="card-body pb-0">
                    <h5 className="card-title">Listado de Categorias</h5>

                    <div className='row'>                          
                            <div className='col-md-2'>
                                <button type="button" className="btn btn-primary btn-sm mt-4 ml-2" onClick={NuevaCategoria} >
                                    <i className="bi bi-person-plus"></i> Nuevo Categoría
                                </button>
                            </div>
                    </div>    

                    {Loading && 
                    <div class="progress mt-3">
                        <div class="progress-bar progress-bar-striped bg-info progress-bar-animated" role="progressbar" style={{width : '100%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>      
                    }      
                     <div className="table-responsive">
                        <table class="table table-striped  mt-2">
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Código</th>
                                <th scope="col">Nombre Categoria</th>
                                <th scope="col">ID</th>
                                <th scope="col">Op</th>
                            </tr>
                            </thead>
                            <tbody>
                            {ListaProveedores.map((cate, index) => 
                            <tr index={cate.id_categoria}>
                                <th scope="row">{index+1}</th>
                                <td>{cate.id_categoria}</td>
                                <td>{cate.nombre_categoria}</td>
                                <td align='center'>{cate.cod_categoria}</td>
                                <td>
                                  <BotonTabla icono='lapiz.png' titulo='Modificar' evento={()=>Modificar(cate)} />
                                  <BotonTabla icono='borrar.png' titulo='Eliminar' evento={()=>Eliminar(cate.id_categoria, cate.nombre_categoria)} />
                                </td>
                            </tr>
                            )}    
                            
                            </tbody>
                        </table>
                    </div>
                    </div>
                </div>
            </section>    


            <div className="modal fade" id="modalCategoria" tabIndex="-1" data-bs-backdrop="static">

            <div className="modal-dialog modal-md">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">
                      <i className="bi bi-pencil-square"></i> {IdCategoria === 0 ? <>Nueva Categoria</> : <>Modificar Categoría N° {IdCategoria}</>}
                    </h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <div className="form-group">
                      <label htmlFor="nombreProv">Nombre *</label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        id="nombreProv"
                        value={NombreCate}
                        onChange={(e) => setNombreCate(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="modal-footer">
                  <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i className="bi bi-door-closed"></i> Cerrar</button>
                    <button type="button" className="btn btn-primary" disabled={!NombreCate} onClick={GuardarCategoria}>Guardar</button>
                  </div>
                </div>
              </div>            

            </div>
    
    </>
  )
}

export default PageCategorias