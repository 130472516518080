import { getFromStorage } from '../components/utils';
import { URL_SERVICIOS } from './UrlWebServis';
import { getToken, getID } from './Usuario';

export function GetData(type){    

    const url_api = URL_SERVICIOS;
    const campus = getFromStorage("contarsEmpresa");

  

    const token = getToken();
    const id = getID();
    
    console.log(id, token, campus);
    
    return new Promise((resolve, reject) => {        
        fetch(url_api+type+'/'+id+'/'+token+'/'+campus)   
        .then((response) => response.json())
        .then((responseJson) => {
            resolve(responseJson);
        })
        .catch((error) => {
           reject(error);
        })
    })    
}