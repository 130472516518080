import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';


import { SessionContext } from './SessionContext';
import PageNoFound from './pages/PageNoFound';

import LoginPage from './pages/auth/LoginPage';
import HomePage from './pages/HomePage';
import ChangePassword from './pages/auth/ChangePassword';
import RegisterPage from './pages/auth/RegisterPage';
import ConfirmarEmailPage from './pages/auth/ConfirmarEmailPage';





function Rutas() {
  
  const { user } = useContext(SessionContext);
  
  return (
    <Router>
        <Routes>
          <Route path="/" element={  !user ? (<LoginPage />):( <Navigate to="/home"/>)} />
          <Route path="/register" element={  !user ? (<RegisterPage />):( <Navigate to="/home"/>)} />
          <Route path="/confirmarcorreo/:error" element={  !user ? (<ConfirmarEmailPage />):( <Navigate to="/home"/>)} />
          <Route path="/home/*" element={ user ? (<HomePage />):( <Navigate to="/"/>)} /> 
          <Route path="/cambiarpassword" element={user ? <ChangePassword /> : <Navigate to="/" />} />
          <Route path="/notfound" element={<PageNoFound />} />
        </Routes>
    </Router>
  );
}

export default Rutas;
