import React, { useEffect, useState } from 'react'
import { GetData } from '../services/GetData'
import { PostData } from '../services/PostData'
import Swal from 'sweetalert2'
import BotonTabla from '../components/BotonTabla'

const PageProveedor = () => {
    const [FiltrarPor, setFiltrarPor] = useState(1)
    const [FiltrarPorText, setFiltrarPorText] = useState('Nombre')
    const [ListaProveedores, setListaProveedores] = useState([])
    const [Loading, setLoading] = useState(false)
    const [Refrest, setRefrest] = useState(false)


    const [IdProveedor, setIdProveedor] = useState(0);
    const [nitProv, setNitProv] = useState('');
    const [nombreProv, setNombreProv] = useState('');
    const [telefonoProv, setTelefonoProv] = useState('');
    const [direccionProv, setDireccionProv] = useState('');
    const [emailProv, setEmailProv] = useState('');
    const [numCuentaProv, setNumCuentaProv] = useState('');
    const [CodProv, setCodProv] = useState('');
  

    useEffect(() => {
        setLoading(true)
        GetData('proveedor').then((result) => {
                if (result.error === false){
                    setListaProveedores(result.proveedores)
                }
                setLoading(false)
        })
      
    }, [Refrest])

  const Modificar = (Prov) => {
        setIdProveedor(Prov.id_proveedor)
        setNitProv(Prov.nit_prov)
        setNombreProv(Prov.nombre_prov)
        setTelefonoProv(Prov.telefono_prov)
        setDireccionProv(Prov.direccion_prov)
        setEmailProv(Prov.email_prov)
        setNumCuentaProv(Prov.num_cuenta_prov)
        setCodProv(Prov.cod_proveedor)

        const modal = document.getElementById('modalProveedor');

        // Verificar si el modal existe antes de intentar abrirlo
        if (modal) {
          // Usar el método modal() para abrir el modal
          const modalInstancia = new window.bootstrap.Modal(modal);
          modalInstancia.show();
        }
  }  

  const NuevoPro = () => {
        
        const modal = document.getElementById('modalProveedor');

        // Verificar si el modal existe antes de intentar abrirlo
        if (modal) {
        // Usar el método modal() para abrir el modal
        const modalInstancia = new window.bootstrap.Modal(modal);
        modalInstancia.show();
        }
    }

    const Eliminar = (id_proveedor, nombre) => {

      Swal.fire({
          title: '¿Estás seguro?',
          text: '¡No podrás revertir esto! ¿Estas seguro de eliminar a: ' + nombre + '?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sí, eliminarlo'
      }).then((result) => {
          if (result.isConfirmed) {
             

              GetData('proveedor/eliminar/' + id_proveedor, null).then ((result) =>{

                  let responseJSON = result;
      
                  if (responseJSON.error === false){
                      Swal.fire({
                          title: 'Eliminado',
                          text: 'El proveedor ha sido eliminado correctamente',
                          icon: 'success'
                      });
                      setRefrest(!Refrest);
                  }
              })
              // Muestra un mensaje de éxito después de eliminar
            
          }
      });
      
  }

    const validarFormulario = () => {
        if (
            nitProv.trim() === '' ||
            nombreProv.trim() === '' ||
            telefonoProv.trim() === '' 
       
        ) {
          alert('Por favor, complete todos los campos requeridos.');
          return false;
        }
        return true;
      };

      const limpiarFormulario = () => {
        setIdProveedor(0)
        setNitProv("")
        setNombreProv("")
        setTelefonoProv("")
        setDireccionProv("")
        setEmailProv("")
        setNumCuentaProv("")
    }

   const GuardarProveedor = async () => {
            if (!validarFormulario()) {
                return;
            }
            
            // Construcción del objeto proveedor
            const datos = {
                id_proveedor: IdProveedor,
                nit_prov: nitProv,
                nombre_prov: nombreProv,
                telefono_prov: telefonoProv,
                direccion_prov: direccionProv,
                email_prov: emailProv,
                num_cuenta_prov: numCuentaProv,
                cod_proveedor: CodProv
                // Puedes agregar más campos aquí según tus necesidades
            };

            try {
                //const response = await axios.post('URL_DE_TU_API/guardar_post', producto);
                const response = await PostData('proveedor/guardar', datos);
                if (!response.error) {
                  Swal.fire({
                      title: 'Cargar Guardado',
                      text: 'Proveedor guardado correctamente.',
                      icon:"success",
                      confirmButtonText: 'Aceptar'
                    });                  
                    limpiarFormulario()
                    setRefrest(!Refrest)
                  // Aquí puedes agregar cualquier otra acción como cerrar el modal, recargar la lista, etc.
                } else {
                  Swal.fire({
                      title: 'Error al Guardar',
                      text: response.mensaje,
                      icon: 'error',
                      confirmButtonText: 'Aceptar'
                    });
                }
              } catch (error) {
                  Swal.fire({
                      title: 'Error al Guardar',
                      text: "Error al guardar el proveedor: " + error,
                      icon: 'error',
                      confirmButtonText: 'Aceptar'
                    });
                console.error('Error al guardar el proveedor:', error);
                
              }
   } 


  const eventFiltro = (texto, codigo) => {
    setFiltrarPor(codigo)
    setFiltrarPorText(texto)
    }


  return (
    <>
         <section className='section dashboard'>
            <div class="card">
                  
                <div className="card-body pb-0">
                    <h5 className="card-title">Listado de Proveedores</h5>

                    <div className='row'>                          
                            
                            <div className='col-md-2'>
                                <button type="button" className="btn btn-primary btn-sm mt-4 ml-2" onClick={NuevoPro} >
                                    <i className="bi bi-person-plus"></i> Nuevo Proveedor
                                </button>
                            </div>
                            <div className='col-md-2 mt-4'>
                                <span className='label'> N° Proveedores:</span> <span className='texto'>{ListaProveedores.length}</span>                                  
                            </div>
                    </div>    

                    {Loading && 
                    <div class="progress mt-3">
                        <div class="progress-bar progress-bar-striped bg-info progress-bar-animated" role="progressbar" style={{width : '100%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>      
                    }      
                     <div className="table-responsive">
                    <table class="table table-striped  mt-2">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Código</th>
                            <th scope="col">Nombre del Proveedor</th>
                            <th scope="col">Teléfono</th>
                            <th scope="col">Dirección</th>
                            <th scope="col">ID</th>
                            <th scope="col">Op</th>
                        </tr>
                        </thead>
                        <tbody>
                        {ListaProveedores.map((pro, index) => 
                        <tr index={pro.id_proveedor}>
                            <th scope="row">{index+1}</th>
                            <th scope="row">{pro.nit_prov}</th>
                            <td> <a href='#' onClick={()=> Modificar(pro)} title='Ver Producto' >{pro.nombre_prov}</a></td>
                            <td align='center'>{pro.telefono_prov}</td>
                            <td align='center'>{pro.direccion_prov}</td>
                            <td align='center'>{pro.cod_proveedor}</td>
                            <td>
                              <BotonTabla icono='lapiz.png' titulo='Modificar' evento={()=>Modificar(pro)} />
                              <BotonTabla icono='borrar.png' titulo='Eliminar' evento={()=>Eliminar(pro.id_proveedor, pro.nombre_prov)} />
                            </td>
                        </tr>
                        )}    
                        
                        </tbody>
                    </table>
                    </div>
                    </div>
                </div>
            </section>    


            <div className="modal fade" id="modalProveedor" tabIndex="-1" data-bs-backdrop="static">

            <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              <i className="bi bi-pencil-square"></i> {IdProveedor === 0 ? <>Nuevo Preveedor</> : <>Modificar Proveedor N° {IdProveedor}</>}
            </h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label htmlFor="nitProv">NIT *</label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="nitProv"
                value={nitProv}
                onChange={(e) => setNitProv(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="nombreProv">Nombre *</label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="nombreProv"
                value={nombreProv}
                onChange={(e) => setNombreProv(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="telefonoProv">Teléfono *</label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="telefonoProv"
                value={telefonoProv}
                onChange={(e) => setTelefonoProv(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="direccionProv">Email</label>
              <input
                type="email"
                className="form-control form-control-sm"
                id="direccionProv"
                value={emailProv}
                onChange={(e) => setEmailProv(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="direccionProv">Dirección</label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="direccionProv"
                value={direccionProv}
                onChange={(e) => setDireccionProv(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="numCuentaProv">Número de Cuenta</label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="numCuentaProv"
                value={numCuentaProv}
                onChange={(e) => setNumCuentaProv(e.target.value)}
              />
            </div>
          </div>
          <div className="modal-footer">
          <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i className="bi bi-door-closed"></i> Cerrar</button>
            <button type="button" className="btn btn-primary" disabled={!nombreProv || !nitProv || !telefonoProv} onClick={GuardarProveedor}>Guardar</button>
          </div>
        </div>
      </div>            

            </div>
    
    </>
  )
}

export default PageProveedor