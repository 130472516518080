import React, { useEffect, useState } from 'react'
import { GetData } from '../services/GetData'
import { PostData } from '../services/PostData'
import Swal from 'sweetalert2'
import BotonTabla from '../components/BotonTabla'

const PageCliente = () => {
    const [FiltrarPor, setFiltrarPor] = useState(1)
    const [FiltrarPorText, setFiltrarPorText] = useState('Nombre')
    const [ListaClientes, setListaClientes] = useState([])
    const [Loading, setLoading] = useState(false)
    const [Refrest, setRefrest] = useState(false)


    const [IdCliente, setIdCliente] = useState(0);
    const [nitCli, setNitCli] = useState('');
    const [nombreCli, setNombreCli] = useState('');
    const [telefonoCli, setTelefonoCli] = useState('');
    const [direccionCli, setDireccionCli] = useState('');
    const [emailCli, setEmailCli] = useState('');
    const [CodCliente, setCodCliente] = useState('');
  

    useEffect(() => {
        setLoading(true)
        GetData('clientes').then((result) => {
                if (result.error === false){
                    setListaClientes(result.clientes)
                }
                setLoading(false)
        })
      
    }, [Refrest])

  const Modificar = (Cli) => {
        setIdCliente(Cli.id_cliente)
        setNitCli(Cli.nit_cli)
        setNombreCli(Cli.nombre_cli)
        setTelefonoCli(Cli.telefono_cli)
        setDireccionCli(Cli.direccion_cli)
        setEmailCli(Cli.email_cli)
        setCodCliente(Cli.cod_cliente)

        const modal = document.getElementById('modalCliente');

        // Verificar si el modal existe antes de intentar abrirlo
        if (modal) {
          // Usar el método modal() para abrir el modal
          const modalInstancia = new window.bootstrap.Modal(modal);
          modalInstancia.show();
        }
  }  

  const NuevoCliente = () => {
        
        setIdCliente(0)
        setNitCli("")
        setNombreCli("")
        setTelefonoCli("")
        setDireccionCli("")
        setEmailCli("")
        setCodCliente("")

        const modal = document.getElementById('modalCliente');

        // Verificar si el modal existe antes de intentar abrirlo
        if (modal) {
        // Usar el método modal() para abrir el modal
        const modalInstancia = new window.bootstrap.Modal(modal);
        modalInstancia.show();
        }
    }

    const Eliminar = (id_cliente, nombre) => {

      Swal.fire({
          title: '¿Estás seguro?',
          text: '¡No podrás revertir esto! ¿Estas seguro de eliminar a: ' + nombre + '?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sí, eliminarlo'
      }).then((result) => {
          if (result.isConfirmed) {
             

              GetData('clientes/eliminar/' + id_cliente, null).then ((result) =>{

                  let responseJSON = result;
      
                  if (responseJSON.error === false){
                      Swal.fire({
                          title: 'Eliminado',
                          text: 'El cliente ha sido eliminado correctamente',
                          icon: 'success'
                      });
                      setRefrest(!Refrest);
                  }
              })
              // Muestra un mensaje de éxito después de eliminar
            
          }
      });
      
  }

    const validarFormulario = () => {
        if (
            nitCli.trim() === '' ||
            nombreCli.trim() === '' ||
            telefonoCli.trim() === '' 
       
        ) {
          alert('Por favor, complete todos los campos requeridos.');
          return false;
        }
        return true;
      };

      const limpiarFormulario = () => {
        setIdCliente(0)
        setNitCli("")
        setNombreCli("")
        setTelefonoCli("")
        setDireccionCli("")
        setEmailCli("")
        setCodCliente("")
    }

   const GuardarCliente = async () => {
            if (!validarFormulario()) {
                return;
            }
            
            // Construcción del objeto proveedor
            const datos = {
                id_cliente: IdCliente,
                nit_cli: nitCli,
                nombre_cli: nombreCli,
                telefono_cli: telefonoCli,
                direccion_cli: direccionCli,
                email_cli: emailCli,
                cod_cliente: CodCliente
                // Puedes agregar más campos aquí según tus necesidades
            };

            try {
                //const response = await axios.post('URL_DE_TU_API/guardar_post', producto);
                const response = await PostData('clientes/guardar', datos);
                if (!response.error) {
                  Swal.fire({
                      title: 'Cargar Guardado',
                      text: 'Cliente guardado correctamente.',
                      icon:"success",
                      confirmButtonText: 'Aceptar'
                    });                  
                    limpiarFormulario()
                    setRefrest(!Refrest)
                  // Aquí puedes agregar cualquier otra acción como cerrar el modal, recargar la lista, etc.
                } else {
                  Swal.fire({
                      title: 'Error al Guardar',
                      text: response.mensaje,
                      icon: 'error',
                      confirmButtonText: 'Aceptar'
                    });
                }
              } catch (error) {
                  Swal.fire({
                      title: 'Error al Guardar',
                      text: "Error al guardar el cliente: " + error,
                      icon: 'error',
                      confirmButtonText: 'Aceptar'
                    });
                console.error('Error al guardar el cliente:', error);
                
              }
   } 


  const eventFiltro = (texto, codigo) => {
    setFiltrarPor(codigo)
    setFiltrarPorText(texto)
    }


  return (
    <>
         <section className='section dashboard'>
            <div class="card">
                  
                <div className="card-body pb-0">
                    <h5 className="card-title">Listado de Clientes</h5>

                    <div className='row'>                          
                            
                            <div className='col-md-2'>
                                <button type="button" className="btn btn-primary btn-sm mt-4 ml-2" onClick={NuevoCliente} >
                                    <i className="bi bi-person-plus"></i> Nuevo Cliente
                                </button>
                            </div>
                            <div className='col-md-2 mt-4'>
                                <span className='label'> N° Clientes:</span> <span className='texto'>{ListaClientes.length}</span>                                  
                            </div>
                    </div>    

                    {Loading && 
                    <div class="progress mt-3">
                        <div class="progress-bar progress-bar-striped bg-info progress-bar-animated" role="progressbar" style={{width : '100%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>      
                    }      
                     <div className="table-responsive">
                    <table class="table table-striped  mt-2">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Código</th>
                            <th scope="col">Nombre del Cliente</th>
                            <th scope="col">Teléfono</th>
                            <th scope="col">Dirección</th>
                            <th scope="col">ID</th>
                            <th scope="col">Op</th>
                        </tr>
                        </thead>
                        <tbody>
                        {ListaClientes.map((cli, index) => 
                        <tr index={cli.id_cliente}>
                            <th scope="row">{index+1}</th>
                            <th scope="row">{cli.nit_cli}</th>
                            <td> <a href='#' onClick={()=> Modificar(cli)} title='Ver Cliente' >{cli.nombre_cli}</a></td>
                            <td align='center'>{cli.telefono_cli}</td>
                            <td align='center'>{cli.direccion_cli}</td>
                            <td align='center'>{cli.cod_cliente}</td>
                            <td>
                              <BotonTabla icono='lapiz.png' titulo='Modificar' evento={()=>Modificar(cli)} />
                              <BotonTabla icono='borrar.png' titulo='Eliminar' evento={()=>Eliminar(cli.id_cliente, cli.nombre_cli)} />
                            </td>
                        </tr>
                        )}    
                        
                        </tbody>
                    </table>
                    </div>
                    </div>
                </div>
            </section>    


            <div className="modal fade" id="modalCliente" tabIndex="-1" data-bs-backdrop="static">

            <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              <i className="bi bi-pencil-square"></i> {IdCliente === 0 ? <>Nuevo Cliente</> : <>Modificar Cliente N° {IdCliente}</>}
            </h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label htmlFor="nitProv">NIT *</label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="nitProv"
                value={nitCli}
                onChange={(e) => setNitCli(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="nombreProv">Nombre *</label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="nombreProv"
                value={nombreCli}
                onChange={(e) => setNombreCli(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="telefonoProv">Teléfono *</label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="telefonoProv"
                value={telefonoCli}
                onChange={(e) => setTelefonoCli(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="direccionProv">Email</label>
              <input
                type="email"
                className="form-control form-control-sm"
                id="direccionProv"
                value={emailCli}
                onChange={(e) => setEmailCli(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="direccionProv">Dirección</label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="direccionProv"
                value={direccionCli}
                onChange={(e) => setDireccionCli(e.target.value)}
              />
            </div>

          </div>
          <div className="modal-footer">
          <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i className="bi bi-door-closed"></i> Cerrar</button>
            <button type="button" className="btn btn-primary" disabled={!nombreCli || !nitCli || !telefonoCli} onClick={GuardarCliente}>Guardar</button>
          </div>
        </div>
      </div>            

            </div>
    
    </>
  )
}

export default PageCliente