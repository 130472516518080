import React, { createContext, useState, useEffect } from 'react';
import { saveToStorage, getFromStorage, removeFromStorage } from './components/utils';

// Crea el contexto de sesión
export const SessionContext = createContext();

// Crea el proveedor de sesión
export const SessionProvider = ({ children }) => {
  // Obtén los datos de usuario almacenados en el almacenamiento local al cargar la aplicación
  //const [user, setUser] = useState(JSON.parse(localStorage.getItem('userContaRs')) || null);
  //const [IdEmpresa, setIdEmpresa] = useState(JSON.parse(localStorage.getItem('contarsEmpresa')) || null);
  
  const storedUser = getFromStorage('userContaRs');
  const storedEmpresa = getFromStorage('contarsEmpresa');

  const [user, setUser] = useState(storedUser || null);
  const [IdEmpresa, setIdEmpresa] = useState(storedEmpresa || null);


  // Función para iniciar sesión
  const login = (userData) => {
    setUser(userData);
  };

  // Función para cerrar sesión
  const logout = () => {
    setUser(null);
  };

  // Guarda los datos de usuario en el almacenamiento local al actualizar el estado de usuario
  useEffect(() => {
    //localStorage.setItem('userContaRs', JSON.stringify(user));
    saveToStorage('userContaRs', user);
  }, [user]);

  useEffect(() => {
    //localStorage.setItem('contarsEmpresa', IdEmpresa);
    saveToStorage('contarsEmpresa', IdEmpresa);

  }, [IdEmpresa]);

  // Define los valores del contexto

  // Retorna el proveedor del contexto con los hijos proporcionados
  return (
    <SessionContext.Provider value={{ user, setUser, login, logout, IdEmpresa, setIdEmpresa }}>
      {children}
    </SessionContext.Provider>
  );
};
