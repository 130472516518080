import React, { useEffect, useState } from 'react'
import { GetData } from '../services/GetData'
import { AnioActual, formatCurrency, MesActual } from '../components/utils'

const PageCierresCaja = () => {
    const mes = MesActual();
    const anio = AnioActual();
    const [Loading, setLoading] = useState(false)
    const [Refrest, setRefrest] = useState(false)
    const [ListaCierres, setListaCierres] = useState([])    
    const [Anio, setAnio] = useState(anio)    
    const [Mes, setMes] = useState(mes)        
    const [TotalUtilidad, setTotalUtilidad] = useState(0)        
    const [TotalContado, setTotalContado] = useState(0)        
    const [TotalCredito, setTotalCredito] = useState(0)        
    const [TotalVentas, setTotalVentas] = useState(0)        

    useEffect(() => {
        setLoading(true)
        GetData('arqueoscaja/listapagedomesanio/' +  Mes + '/' + Anio).then((result) => {
                if (result.error === false){
                    setListaCierres(result.listado)

                    const sumaUtilidadVentas = result.listado.reduce((acc, item) => {
                        return acc + (parseFloat(item.utilidad_ventas) || 0); // Convertir a número y sumar
                    }, 0);
                    const sumaVentasContado = result.listado.reduce((acc, item) => {
                        return acc + (parseFloat(item.ventas_contado) || 0); // Convertir a número y sumar
                    }, 0);
                    const sumaVentasCredito = result.listado.reduce((acc, item) => {
                        return acc + (parseFloat(item.ventas_credito) || 0); // Convertir a número y sumar
                    }, 0);
        
                    setTotalUtilidad(sumaUtilidadVentas);
                    setTotalContado(sumaVentasContado);
                    setTotalCredito(sumaVentasCredito);
                    setTotalVentas(sumaVentasContado+sumaVentasCredito)
                }
                setLoading(false)
        })
    }, [Anio, Mes])
    

  return (
    <section className='section dashboard'>
            <div class="card">
                  
                <div className="card-body pb-0">
                    <h5 className="card-title">Listado de Cierres Caja</h5>

                    <div className='row'>                          
                            <div className='col-md-2'>
                                <span className='label'>Mes</span>
                                <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={Mes} onChange={(e) => setMes(e.target.value)} >
                                <option  value="1">Enero</option>
                                <option  value="2">Febrero</option>
                                <option  value="3">Marzo</option>
                                <option  value="4">Abril</option>
                                <option  value="5">Mayo</option>
                                <option  value="6">Junio</option>
                                <option  value="7">Julio</option>
                                <option  value="8">Agosto</option>
                                <option  value="9">Septiembre</option>
                                <option  value="10">Octubre</option>
                                <option  value="11">Noviembre</option>
                                <option  value="12">Diciembre</option>              
                                </select> 
                            </div>
                            <div className='col-md-2'>
                                 <span className='label'>Año</span> 
                                <input type="text" style={{ backgroundColor: '#eaf2ff', color: '#004085', fontWeight: 'bold' }} 
                                  className="form-control form-control-sm" id="fnombres" name="fnombres" value={Anio} onChange={(e) => setAnio(e.target.value)} />                                  
                            </div>
                            <div className='col-md-2 mt-4'>
                                <span className='label'> N° Cuadres:</span> <span className='texto'>{ListaCierres.length}</span>                                  
                            </div>
                            <div className='col-md-2 mt-4'>
                                <span className='label'> Utilidad:</span> <span className='texto'>{formatCurrency(TotalUtilidad)}</span>                                  
                            </div>
                    </div>    
                    <div className='row'>
                            <div className='col-md-3 mt-4'>
                                <span className='label'>Total Contado:</span> <span className='texto'>{formatCurrency(TotalContado)}</span>                                  
                            </div>
                            <div className='col-md-3 mt-4'>
                                <span className='label'>Total Crédito:</span> <span className='texto'>{formatCurrency(TotalCredito)}</span>                                  
                            </div>
                            <div className='col-md-3 mt-4'>
                                <span className='label'>Total Ventas:</span> <span className='texto'>{formatCurrency(TotalVentas)}</span>                                  
                            </div>
                    </div>

                    {Loading && 
                    <div class="progress mt-3">
                        <div class="progress-bar progress-bar-striped bg-info progress-bar-animated" role="progressbar" style={{width : '100%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>      
                    }      
                     <div className="table-responsive">
                    <table class="table table-striped  mt-2">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Fecha</th>
                            <th scope="col">Base</th>
                            <th scope="col">V. Cont</th>
                            <th scope="col">V. Cred</th>
                            <th scope="col">Nequi</th>
                            <th scope="col">Bancos</th>
                            <th scope="col">Pagos Cli</th>
                            <th scope="col">Pagos Prov</th>
                            <th scope="col">Gastos</th>
                            <th scope="col">Inventario</th>
                            <th scope="col">Utilidad</th>
                        </tr>
                        </thead>
                        <tbody>
                        {ListaCierres.map((cli, index) => 
                        <tr index={cli.id_cliente}>
                            <th scope="row">{index+1}</th>
                            <th scope="row">{cli.fecha_arq}</th>
                            <td>{formatCurrency(cli.base)}</td>
                            <td>{formatCurrency(cli.ventas_contado)}</td>
                            <td>{formatCurrency(cli.ventas_credito)}</td>
                            <td>{formatCurrency(cli.nequi)}</td>
                            <td>{formatCurrency(cli.bancos)}</td>
                            <td>{formatCurrency(cli.pagos_clientes)}</td>
                            <td>{formatCurrency(cli.pagos_proveedor)}</td>
                            <td>{formatCurrency(cli.gastos)}</td>
                            <td>{formatCurrency(cli.valor_inventario)}</td>
                            <td>{formatCurrency(cli.utilidad_ventas)}</td>                          
                        </tr>
                        )}    
                        
                        </tbody>
                    </table>
                    </div>
                    </div>
                </div>
            </section>    
  )
}

export default PageCierresCaja